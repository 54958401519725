import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Grid, // Import Grid for layout
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import Constants from "../../../../constants/constants";

function HotelDetails(props) {
  const location = useLocation();

  const {
    hotelId,
    name,
    address,
    latitude,
    longitude,
    description,
    createAt,
    updatedAt,
  } = location.state?.rowData || {};

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false); // Initialize status as false initially

  const Field = ({ label, value }) => {
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              {label}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
      <Card sx={{ width: '90%', p: 2 }}>
        <CardHeader
          title={
            <Typography variant="h6" component="div" sx={{ textDecoration: 'underline', backgroundColor: Constants.cyanblue, textAlign: 'center'}}>
              Hotel Detail
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Field label="Hotel Id" value={hotelId} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field label="Latitude" value={latitude} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field label="Longitude" value={longitude} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field label="Address" value={address} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field label="Description" value={description} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field label="Hotel Name" value={name} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field label="Created at" value={createAt} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field label="Updated at" value={updatedAt} />
            </Grid>
          </Grid>

          <ToastContainer position="top-center" />
        </CardContent>
      </Card>
    </Box>
  );
}

export default HotelDetails;
