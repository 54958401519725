import React, { useState, useEffect } from 'react';
import { Box, TextField, Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BACKEND_API } from '../../../../utils/API';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const OfficeList = ({ setSelectedOffice, selectedOffice, handleOfficeChange }) => {
  const [office, setOffices] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await BACKEND_API.get(`/office/getOffices`);
        const officeData = response.data;
        setOffices(officeData);
      } catch (error) {
        console.error('Error fetching office:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedOffice.length > 0) {
      setSelectedOffice(selectedOffice);
    }
  }, [selectedOffice]);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={office}
      disableCloseOnSelect
      value={selectedOffice}
      onChange={(event, newValue) => {
        handleOfficeChange(event, newValue); // Call the handleOfficeChange function
      }}
      isOptionEqualToValue={(option, value) => option.officeId === value.officeId}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Assign Office" />
      )}
    />
  );
};

export default OfficeList;
