
import React from "react"
export const columns =  [
    {
        accessorKey: 'hotelId',
        header: 'ID',
        enableEditing: false,
        size: 80,
      },    
      {
        accessorKey: 'name',
        header: 'Hotel Name'
        
      },
      {
        accessorKey: 'address',
        header: 'Hotel_Address',
      },

      {
        accessorKey: 'latitude',
        header: 'Hotel_Latitude',
      },    
      {
        accessorKey: 'longitude',
        header: 'Hotel_Longitude',
      },
      {
        accessorKey: 'description',
        header: 'Hotel_Description',
      },
      {
        accessorKey: 'destination.name',
        header: 'Hotel_Destination',
      

      // Cell: ({ cell }) => <p>{cell.getValue()[0].destination.name}</p>,
      // Cell: ({ cell }) => <p>{console.log('sss',cell.getValue()[0]?.destination.name)}</p>,
    }
  ]

 