import React, { useState, useEffect } from 'react';
import { Box, TextField, Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BACKEND_API } from '../../../../utils/API';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectMultiDestination = ({ setSelectedDestination, selectedDestination, handleDestinationChange }) => {
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await BACKEND_API.get(`/destination/getDestinations`);
        const destinationData = response.data;
        setDestinations(destinationData);
      } catch (error) {
        console.error('Error fetching office:', error);
      }
    };

    fetchDestinations();
  }, []);

  useEffect(() => {
    if (selectedDestination.length > 0) {
      setSelectedDestination(selectedDestination);
    }
  }, [selectedDestination]);

  return (
    <Autocomplete
    multiple
    id="checkboxes-tags-demo"
    options={destinations}
    disableCloseOnSelect
    value={selectedDestination}
    onChange={(event, newValue) => {
      handleDestinationChange(event, newValue); // Call the handleOfficeChange function
    }}
    isOptionEqualToValue={(option, value) => option.destinationId === value.destinationId}
    getOptionLabel={(option) => option.name}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.name}
      </li>
    )}
    renderInput={(params) => (
      <TextField {...params} label="Assign Destination" />
    )}
  />
);
};

export default SelectMultiDestination;
