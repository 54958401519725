import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Autocomplete,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import { useLocation } from "react-router-dom";
import SelectMultiTourOperator from "./SelectMultiTourOperator";
import SelectMultiDestination from "../TourOperator/SelectMultiDestination";

export default function UpdatePackage({ open, handleClose }) {
    const location = useLocation();
    const packageId = location.state?.rowData?.packageId;

    const [loading, setLoading] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState([]);
    const [selectedTourOperator, setSelectedTourOperator] = useState([]);

    const tourCategoryList = ["water body", "mountains", "Historical place"];
    const touristTypeList = ["International", "Demostic"];
    const [initialValues, setInitialValues] = useState({
        packageName: "",
        stayDuration: "",
        packagePricePerPerson: "",
        maxGroup: "",
        touristType: [],
        packageForDorInter: [],
        packageDescription: "",
        departureDates: "",
        destinations: [],
        tourOperator: [],
    });

    const orgValidationSchema = yup.object({
        packageName: yup.string().required("Package Name required"),
        stayDuration: yup.string().required("Stay duration required"),
        packagePricePerPerson: yup.string().required("Price Per-person required"),
        maxGroup: yup.string().required("Max number of group is required "),
        touristType: yup.array()
            .min(1, 'At least one Tourist type is required')
            .required('Tourist type is required'),
        packageForDorInter: yup.array()
            .min(1, 'At least one category is required')
            .required('Tour category is required'),
        packageDescription: yup.string().required("Package description required"),
        departureDates: yup.string().required("Departure date is required"),
        tourOperator: yup
            .array()
            .of(
                yup.object({
                    tourOperatorId: yup.string().required(),
                    tourOrgName: yup.string().required(),
                })
            )
            .required("At least one tour operator is required"),

        destinations: yup
            .array()
            .of(
                yup.object({
                    destinationId: yup.string().required(),
                    name: yup.string().required(),
                })
            )
            .required("At least one destination is required"),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: orgValidationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const response = await BACKEND_API.put(
                    `/tourPackage/edit/${packageId}`,
                    {
                        ...values,
                        destinations: selectedDestination,
                        tourOperator: selectedTourOperator

                    }
                );
                if (response.status === 200 || response.status === 201) {
                    toast.success(
                        response?.data?.message || "Package updated successfully!"
                    );
                    handleClose();
                }
            } catch (error) {
                toast.error(error?.response?.data?.message || "Network error...");
            } finally {
                setLoading(false);
            }
        },
    });
    useEffect(() => {
        const fetchPackageDetail = async () => {
            try {
                const response = await BACKEND_API.get(`/tourPackage/getpackages/${packageId}`);
                const userData = response.data;
                const initialDestination = userData.destinations?.map(destination => ({
                    name: destination.name,
                    destinationId: destination.destinationId,
                })) || [];

                const initialTourOperator = userData.tourOperator?.map(tourOperator1 => ({
                    tourOrgName: tourOperator1.tourOrgName,
                    tourOperatorId: tourOperator1.tourOperatorId,
                })) || [];

                setInitialValues({
                    packageName: userData.packageName || "",
                    stayDuration: userData.stayDuration || "",
                    packagePricePerPerson: userData.packagePricePerPerson || "",
                    maxGroup: userData.maxGroup || "",
                    touristType: userData.touristType || [],
                    packageForDorInter: userData.packageForDorInter || [],
                    packageDescription: userData.packageDescription || "",
                    departureDates: userData.departureDates || "",
                    destinations: initialDestination,
                    tourOperator: initialTourOperator,

                });

                setSelectedDestination(initialDestination);
                setSelectedTourOperator(initialTourOperator);
            } catch (error) {
                console.error("Error fetching package details:", error);
            }
        };

        if (packageId) {
            fetchPackageDetail();
        }
    }, [packageId]);

    const handleDestinationChange = (event, newValue) => {
        setSelectedDestination(newValue);
        formik.setFieldValue("destinations", newValue);
    };

    const handleTourOperatorChange = (event, newValue) => {
        setSelectedTourOperator(newValue);
        formik.setFieldValue("tourOperator", newValue);
    };

    // Handlers for Autocomplete changes
    const handleTouristTypeChange = (event, newValue) => {
        formik.setFieldValue("touristType", newValue);
    };

    const handlePackageForDorInterChange = (event, newValue) => {
        formik.setFieldValue("packageForDorInter", newValue);
    };
    return (          // department: userData.department,

        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update Package Detail</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please update Tour Operator information and select Destination.
                </DialogContentText>
                <Box component={Paper} p={3} mt={2}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Package Name"
                                    {...formik.getFieldProps("packageName")}
                                    error={
                                        formik.touched.packageName &&
                                        Boolean(formik.errors.packageName)
                                    }
                                    helperText={
                                        formik.touched.packageName &&
                                        formik.errors.packageName
                                    }
                                />
                            </Grid>


                            <Grid item xs={12} >
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={touristTypeList}
                                    disableCloseOnSelect
                                    value={formik.values.touristType}
                                    onChange={handleTouristTypeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tourist Type"
                                            error={formik.touched.touristType && Boolean(formik.errors.touristType)}
                                            helperText={formik.touched.touristType && formik.errors.touristType}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={tourCategoryList}
                                    disableCloseOnSelect
                                    value={formik.values.packageForDorInter}
                                    onChange={handlePackageForDorInterChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Package Category"
                                            error={formik.touched.packageForDorInter && Boolean(formik.errors.packageForDorInter)}
                                            helperText={formik.touched.packageForDorInter && formik.errors.packageForDorInter}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Price Per-person"
                                    {...formik.getFieldProps("packagePricePerPerson")}
                                    error={
                                        formik.touched.packagePricePerPerson &&
                                        Boolean(formik.errors.packagePricePerPerson)
                                    }
                                    helperText={
                                        formik.touched.packagePricePerPerson &&
                                        formik.errors.packagePricePerPerson
                                    }
                                />
                            </Grid>


                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Duration of Stay"
                                    {...formik.getFieldProps("stayDuration")}
                                    error={
                                        formik.touched.stayDuration &&
                                        Boolean(formik.errors.stayDuration)
                                    }
                                    helperText={
                                        formik.touched.stayDuration &&
                                        formik.errors.stayDuration
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="max number of Group"
                                    {...formik.getFieldProps("maxGroup")}
                                    error={
                                        formik.touched.maxGroup &&
                                        Boolean(formik.errors.maxGroup)
                                    }
                                    helperText={
                                        formik.touched.maxGroup &&
                                        formik.errors.maxGroup
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" Package Description"
                                    {...formik.getFieldProps("packageDescription")}
                                    error={
                                        formik.touched.packageDescription &&
                                        Boolean(formik.errors.packageDescription)
                                    }
                                    helperText={
                                        formik.touched.packageDescription &&
                                        formik.errors.packageDescription
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    label="Departure date"
                                    {...formik.getFieldProps("departureDates")}
                                    error={
                                        formik.touched.departureDates && Boolean(formik.errors.departureDates)
                                    }
                                    helperText={
                                        formik.touched.departureDates && formik.errors.departureDates
                                    }
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Tin Number"
                                    {...formik.getFieldProps("tinNum")}
                                    error={
                                        formik.touched.tinNum &&
                                        Boolean(formik.errors.tinNum)
                                    }
                                    helperText={
                                        formik.touched.tinNum &&
                                        formik.errors.tinNum
                                    }
                                />
                            </Grid> */}



                            <Grid item xs={12}>
                                <SelectMultiDestination
                                    handleDestinationChange={handleDestinationChange}
                                    selectedDestination={selectedDestination}
                                    setSelectedDestination={setSelectedDestination}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <SelectMultiTourOperator
                                    handleTourOperatorChange={handleTourOperatorChange}
                                    selectedTourOperator={selectedTourOperator}
                                    setSelectedTourOperator={setSelectedTourOperator}
                                />
                            </Grid>

                        </Grid>
                        <DialogActions>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "red",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#d32f2f",
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#03930a",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#027c08",
                                    },
                                }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress size={20} sx={{ color: "#FFF" }} />
                                ) : (
                                    "Update Tour OP Detail"
                                )}
                            </Button>
                        </DialogActions>
                    </form>
                </Box>
                <ToastContainer position="top-center" />
            </DialogContent>
        </Dialog>
    );
}
