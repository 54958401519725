import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import { useLocation } from "react-router-dom";
import SelectMultiDestination from "./SelectMultiDestination";

export default function EditTourOperator({ open, handleClose}) {
const location = useLocation();
const tourOperatorId = location.state?.rowData?.tourOperatorId;

    const [loading, setLoading] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState([]);

    const [initialValues, setInitialValues] = useState({
        tourOrgName: "",
        ownerFullName: "",
        ownerAddress: "",
        email: "",
        phoneNum: "",
        foundAt: "",
        tinNum: "",
        // touristType: [],
        // tourCategory: [],
        destinations: [], // Add departmentName here
    });

    const orgValidationSchema = yup.object({
        tourOrgName: yup.string().required("Tour Org Name required"),
        ownerFullName: yup.string().required("Owner Full Name required"),
        ownerAddress: yup.string().required("Tour Op address required"),
        email: yup
            .string()
            .email("Invalid  email")
            .required(" Email required"),
        phoneNum: yup.string().required("Phone number required"),
        foundAt: yup.string().required("found at required"),
        tinNum: yup.string().required("Tin Number required"),
        destinations: yup
            .array()
            .of(
                yup.object({
                    destinationId: yup.string().required(),
                    name: yup.string().required(),
                })
            )
            .required("At least one destination is required"),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: orgValidationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const response = await BACKEND_API.put(
                    `/tourOperator/edit/${tourOperatorId}`,
                    {
                        ...values,
                        destinations: selectedDestination
                    }
                );
                if (response.status === 200 || response.status === 201) {
                    toast.success(
                        response?.data?.message || "Tour Operator updated successfully!"
                    );
                    handleClose();
                }
            } catch (error) {
                toast.error(error?.response?.data?.message || "Network error...");
            } finally {
                setLoading(false);
            }
        },
    });
    useEffect(() => {
        const fetchTourOperatorDetails = async () => {
            try {
                const response = await BACKEND_API.get(`/tourOperator/getTourOperator/${tourOperatorId}`);
                const userData = response.data;
                const initialDestination = userData.destinations?.map(destination => ({
                    name: destination.name,
                    destinationId: destination.destinationId,
                })) || [];
    
                setInitialValues({
                    tourOrgName: userData.tourOrgName || "",
                    ownerFullName: userData.ownerFullName || "",
                    ownerAddress: userData.ownerAddress || "",
                    email: userData.email || "",
                    phoneNum: userData.phoneNum || "",
                    foundAt: userData.foundAt || "",
                    tinNum: userData.tinNum || "",
                    destinations: initialDestination,
                });
    
                setSelectedDestination(initialDestination);
            } catch (error) {
                console.error("Error fetching tour operator details:", error);
            }
        };
    
        if (tourOperatorId) {
            fetchTourOperatorDetails();
        }
    }, [tourOperatorId]);
    
    const handleDestinationChange = (event, newValue) => {
        setSelectedDestination(newValue);
        formik.setFieldValue("destinations", newValue);
    };
const genderList = ["Male", "Female", "Others"];
    return (          // department: userData.department,

        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update Tour Operator Detail</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please update Tour Operator information and select Destination.
                </DialogContentText>
                <Box component={Paper} p={3} mt={2}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Tour Org Name"
                                    {...formik.getFieldProps("tourOrgName")}
                                    error={
                                        formik.touched.tourOrgName &&
                                        Boolean(formik.errors.tourOrgName)
                                    }
                                    helperText={
                                        formik.touched.tourOrgName &&
                                        formik.errors.tourOrgName
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="owner Full Name"
                                    {...formik.getFieldProps("ownerFullName")}
                                    error={
                                        formik.touched.ownerFullName &&
                                        Boolean(formik.errors.ownerFullName)
                                    }
                                    helperText={
                                        formik.touched.ownerFullName &&
                                        formik.errors.ownerFullName
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="owner Address"
                                    {...formik.getFieldProps("ownerAddress")}
                                    error={
                                        formik.touched.ownerAddress &&
                                        Boolean(formik.errors.ownerAddress)
                                    }
                                    helperText={
                                        formik.touched.ownerAddress &&
                                        formik.errors.ownerAddress
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" Email"
                                    {...formik.getFieldProps("email")}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    {...formik.getFieldProps("phoneNum")}
                                    error={
                                        formik.touched.phoneNum &&
                                        Boolean(formik.errors.phoneNum)
                                    }
                                    helperText={
                                        formik.touched.phoneNum &&
                                        formik.errors.phoneNum
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Tin Number"
                                    {...formik.getFieldProps("tinNum")}
                                    error={
                                        formik.touched.tinNum &&
                                        Boolean(formik.errors.tinNum)
                                    }
                                    helperText={
                                        formik.touched.tinNum &&
                                        formik.errors.tinNum
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    // label="found At"
                                    {...formik.getFieldProps("foundAt")}
                                    error={
                                        formik.touched.foundAt && Boolean(formik.errors.foundAt)
                                    }
                                    helperText={
                                        formik.touched.foundAt && formik.errors.foundAt
                                    }
                                />
                            </Grid>

                            {/* <Grid item xs={12} md={6}>
                                <Autocomplete
                                    fullWidth
                                    options={genderList}
                                    value={formik.values.gender}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("gender", newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Gender"
                                            error={
                                                formik.touched.gender && Boolean(formik.errors.gender)
                                            }
                                            helperText={
                                                formik.touched.gender && formik.errors.gender
                                            }
                                        />
                                    )}
                                />
                            </Grid> */}


                            <Grid item xs={12}>
                                <SelectMultiDestination
                                    handleDestinationChange={handleDestinationChange}
                                    selectedDestination={selectedDestination}
                                    setSelectedDestination={setSelectedDestination}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "red",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#d32f2f",
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#03930a",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#027c08",
                                    },
                                }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress size={20} sx={{ color: "#FFF" }} />
                                ) : (
                                    "Update Tour OP Detail"
                                )}
                            </Button>
                        </DialogActions>
                    </form>
                </Box>
                <ToastContainer position="top-center" />
            </DialogContent>
        </Dialog>
    );
}
