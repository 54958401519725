import React from "react";
import MDataTable from "../MDataTable";

import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { columns } from "./columns";
import useDeleteData from "../hooks/useDeleteData";
import usePutData from "../hooks/usePutData";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Packages() {
  const [packageId, setPackageId] = React.useState(null);

  let endpoint =  `/tourPackage/registerPackage` ;
  let getEndpoint = `/tourPackage/getPackages`;        
  let deleteEndpoint = `/tourPackage/delete/package`
  let editEndpoint = `/tourOperator/edit`


  const {
    data: response,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    isFetching: isFetchingTax,
    error: errorGet,
  } = useGetData(getEndpoint);

  const { mutate, isLoading, isError, data, isSuccess } = usePostData(endpoint);
  const handleNewAdd = async ({ values, table }) => {
    mutate(values);

    table.setCreatingRow(null);
  };

  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: setSuccessUpdate,
    error: errorupdate,
  } = usePutData(editEndpoint,packageId, data);

  const handleEdit = async ({ row, values, table }) => {
    update({
      endpoint: editEndpoint,
      Id: row.original.packageId,
      data: values,
    });
    table.setEditingRow(null);
  };

  const { mutateAsync: deleteData, isPending: isDeleting } = useDeleteData(deleteEndpoint, packageId);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this Package?")) {
      deleteData({ endpoint: deleteEndpoint, Id: row.original.packageId });
    }
  };

  const navigate = useNavigate();
  const handleViewClick = (rowData) => {
    navigate('/dashboard/package/detail', { state: { rowData } })
  }

  return (
    <div>
      <MDataTable
        headerTitle="Packages"
        add="Package"
        openDeleteConfirmModal={openDeleteConfirmModal}
        handleNewAdd={handleNewAdd}
        handleEdit={handleEdit}
        editable={true}
        columns={columns}
        data={response || []}
        isLoading={isLoadingGet}
        isError={isErrorGet}
        title={"Package"}
        handleViewClick={handleViewClick}
      />
            <ToastContainer position="top-center" />

    </div>
  );
}
export default Packages;
