import React from "react";
import Dashboard from "./components/DashBoard/Dashboard";
import theme from "./assets/theme";
import Footer from "./components/Footer/footer";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Destination from "./components/DashBoard/DataTable/Destination/Destination";
import Tourist from "./components/DashBoard/DataTable/Tourist/Tourist";
import Hotels from "./components/DashBoard/DataTable/Hotel/Hotel";
import Offices from "./components/DashBoard/DataTable/Office/Office";
import UserManagement from "./components/DashBoard/DataTable/UserManagement/UserManagement";
import TourOperator from "./components/DashBoard/DataTable/TourOperator/TourOperator";
import Packages from "./components/DashBoard/DataTable/Package/Package";
import Employee from "./components/DashBoard/DataTable/Employee/Employee";
import Banks from "./components/DashBoard/DataTable/Bank/Bank";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import Reports from "./components/DashBoard/Reports";
import PrivateRoutes from "./services/api/PrivateRoutes";
import DestinationDetails from "./components/DashBoard/DataTable/Destination/DestinationDetails";
import Index from "./components/Appbar";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/RestPassword";
import Login from "./pages/Authentication/Login";
import Homepage from "./components/Homepage"
import Registration from "./pages/Authentication/Registration";
import UserDetail from "./components/DashBoard/DataTable/UserManagement/UserDetail";
import BankDetail from "./components/DashBoard/DataTable/Bank/BankDetail";
import HotelDetails from "./components/DashBoard/DataTable/Hotel/HotelDetails";
import OfficeDetail from "./components/DashBoard/DataTable/Office/OfficeDetail";
import EmployeeDetail from "./components/DashBoard/DataTable/Employee/EmployeeDetail";
import TourOperatorDetail from "./components/DashBoard/DataTable/TourOperator/TourOperatorDetail";
import PackageDetail from "./components/DashBoard/DataTable/Package/PackageDetail";
import TouristDetail from "./components/DashBoard/DataTable/Tourist/TouristDetail";



function App() {
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Index />

          <Routes>
            <Route path="/" element={<Homepage/>} />
            <Route path="/login" element={<Login />} />

            <Route path="/signup" element={<Registration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:username/:token" element={<ResetPassword />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" name="dashboard" element={<Dashboard />} >
                <Route index element={<Reports />}></Route>
                <Route path={"reports"} element={<Reports />}></Route>
                <Route path={"destinantion"} element={<Destination />}  ></Route>
                <Route path={"destinantion/details"} element={<DestinationDetails />} ></Route>
                <Route path={"hotel/details"} element={<HotelDetails />} ></Route>
                <Route path={"hotels"} element={<Hotels />}></Route>
                <Route path={"tourist"} element={<Tourist />}></Route>
                <Route path={"tour-detail"} element={<TouristDetail />}></Route>
                <Route path={"banks"} element={< Banks />}></Route>
                <Route path={"bank-detail"} element={<BankDetail />}  ></Route>
                <Route path={"offices"} element={<Offices />}></Route>
                <Route path={"office-detail"} element={<OfficeDetail />}></Route>
                <Route path={"userManagement"} element={<UserManagement />}  ></Route>
                <Route path={"user-detail"} element={<UserDetail />}  ></Route>
                <Route path={"tourOperator"} element={<TourOperator />} ></Route>
                <Route path={"tour-operator-detail"} element={<TourOperatorDetail />} ></Route>
                <Route path={"packages"} element={<Packages />}></Route>
                <Route path={"package/detail"} element={<PackageDetail />}></Route>
                <Route path={"employee"} element={<Employee />}></Route>
                <Route path={"employee-detail"} element={<EmployeeDetail />}></Route>
              </Route>
            </Route>
          </Routes>
          <Footer />
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>

  );
}
export default App;
