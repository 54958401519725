import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import Constants from "../../../../constants/constants";
import EditTourOperator from "./EditTourOperator";

function TourOperatorDetail(props) {
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false); // Example of disabling logic
  const {
    tourOrgName,
    ownerFullName,
    ownerAddress,
    email,
    phoneNum,
    createdAt,
    updatedAt,
    tinNum,
    destinations = [], // Add departmentName here
  } = location.state?.rowData || {};
  const tourOperatorId = location.state?.rowData?.tourOperatorId;

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false); // Initialize status as false initially

  const [open, setOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleClickOpen = (type) => {
    setOpen(true);
    setPopupType(type);
  };

  const handleClose = () => {
    setOpen(false);
    setPopupType(null);
  };

  useEffect(() => {
    // Fetch the initial status from the API when the component mounts
    const fetchStatus = async () => {
      try {
        const response = await BACKEND_API.get(`/tourOperator/getTourOperator/${tourOperatorId}`);
        if (response.status === 200) {
          setStatus(response.data.isActive);
        }
      } catch (error) {
        toast.error("Failed to fetch the initial status");
      }
    };

    fetchStatus();
  }, [tourOperatorId]);

  const destinationNames = destinations
    ?.map((destination) => destination.name)
    .join(", ");

  const Field = ({ label, value }) => {
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              {label}
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const TextAreaField = ({ label, value }) => {
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Stack direction={"column"} spacing={1}>
            <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
              {label}
            </Typography>
            <TextField
              value={value}
              multiline
              fullWidth
              rows={8}
              variant="outlined"
              InputProps={{
                readOnly: true,
                sx: {
                  "& .MuiInputBase-input": {
                    textAlign: "justify",
                    textJustify: "inter-word",
                  },
                },
              }}
            />
          </Stack>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2,
        height: "74vh",
        position: "relative",
      }}
    >
      <Card
        sx={{
          width: "100%",
          p: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h6"
              component="div"
              sx={{
                textDecoration: "underline",
                backgroundColor: Constants.cyanblue,
                textAlign: "center",
              }}
            >
              Tour Operator Detail
            </Typography>
          }
        />
        <CardContent sx={{ flex: 1, overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Tour Operator Id" value={tourOperatorId} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Tour Operator Org Name" value={tourOrgName} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Owner Full Name" value={ownerFullName} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Tour OP Office Address" value={ownerAddress} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Office or Individual Phone Number" value={phoneNum} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Corporate Email" value={email} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Created At" value={createdAt} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Updated At" value={updatedAt} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Tin Number" value={tinNum} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Field label="Destinations" value={destinationNames} />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green", // Set background color to #03930a
                color: "white", // Set text color to white or any color you prefer
                "&:hover": {
                  backgroundColor: "#027c08", // Change color on hover if needed
                },
                width: "20%", // Reduce the button size
              }}
              onClick={() => handleClickOpen("UpdateRole")}
            >
              Update Detail
            </Button>
          </Box>

          {popupType === "UpdateRole" && (
            <EditTourOperator
              tourOperatorId={tourOperatorId}
              open={open}
              handleClose={handleClose}
            />
          )}
          <ToastContainer position="top-center" />
        </CardContent>
      </Card>
    </Box>
  );
}

export default TourOperatorDetail;
