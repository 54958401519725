import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Autocomplete,
  Typography,
  FormControl,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import DestinationSelect from "./SelectDestination";
import TourOperatorSelect from "./SelectTourOperator";
import "react-toastify/dist/ReactToastify.css";

export default function EditUser({ open, handleClose, userId }) {
  // console.log("helo")

  const [loading, setLoading] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [selectedTourOperator, setSelectedTourOperator] = useState(null);
  const genderList = ["Male", "Female", "Other"];
  const rolesOptions = [
    { roleName: "admin", description: "Administrator" },
    { roleName: "System Admin", description: "System Administrator" },
    { roleName: "Market Analyst", description: "Market Analyst" },
    { roleName: "employee", description: "Employee" },
    { roleName: "Tour Operator", description: "Create Package" },
  ];

  const [selectedRoles, setSelectedRoles] = useState([]);

  const countrylist = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  // Initial form values
  const [initialValues, setInitialValues] = useState({
    fullName: "",
    email: "",
    gender: "",
    phoneNumber: "",
    description: "",
    birthDate: "",
    tourOperator: null,
    destination: null,
    address: {
      country: "",
      city: "",
      subCity: "",
      woreda: "",
      houseNumber: " ",
    },
    emailConfirmed: false,
    isActive: true,
    roles: [],
  });

  // Validation schema using Yup
  const validationSchema = yup.object().shape({
    // username: yup.string().required("Username is required"),
    fullName: yup.string().required("Full Name is required"),
    email: yup.string().email("Invalid email format").required("Email is required"),
    gender: yup.string().oneOf(genderList, "Select a valid gender").required("Gender is required"),
    phoneNumber: yup
      .string()
      .required("a valid phone number required"),
    description: yup.string(),
    birthDate: yup
      .date()
      .max(new Date(), "Birth Date cannot be in the future")
      .required("Birth Date is required"),
    address: yup.object().shape({
      country: yup.string().required("Country is required"),
      city: yup.string().required("City is required"),
      subCity: yup.string(),
      woreda: yup.string(),
      houseNumber: yup.string(),
    }),
    roles: yup
      .array()
      .of(yup.string().required())
      .min(1, "At least one role must be selected"),
    // tourOperator: yup
    //   .object({
    //     tourOperatorId: yup.string(),
    //   }),
      // .required("Tour Operator required"),

    destination: yup
      .object({
        destinationId: yup.string().required(),
      })
      .required("Tour Operator required"),


  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true, // Ensure formik reinitializes when initialValues change
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await BACKEND_API.put(`/api/v1/user/${userId}`, {
          ...values,
          tourOperator: selectedTourOperator,
          destination: selectedDestination,
          roles: values.roles.map((roleName) => ({ roleName })),
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(response?.data?.message || "User updated successfully!");
          handleClose(); // Close the dialog after showing the success message
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Network error...", {});
      } finally {
        setLoading(false);
      }
    },
  });

  // Fetch user details and roles options on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await BACKEND_API.get(`/api/v1/user/getUser/${userId}`);
       console.log("helo")
        const userData = response.data;
        const initialRoles = userData.roles ? userData.roles.map((role) => role.roleName) : [];
        const initialDestination = userData.destination
          ? {
            destinationId: userData.destination.destinationId,
            name: userData.destination.name,
          }
          : null;

        const initialTourOperator = userData.tourOperator
          ? {
            tourOperatorId: userData.tourOperator.tourOperatorId,
            tourOrgName: userData.tourOperator.tourOrgName,
          }
          : null;
        // console.log("userData", userData)


        setInitialValues({
          // username: userData.username || '',
          fullName: userData.fullName || '',
          email: userData.email || '',
          gender: userData.gender || '',
          phoneNumber: userData.phoneNumber || '',
          description: userData.description || '',
          birthDate: userData.birthDate || '',
          address: {
            country: userData.address?.country || "",
            city: userData.address?.city || "",
            subCity: userData.address?.subCity || "",
            woreda: userData.address?.woreda || "",
            houseNumber: userData?.address?.houseNumber || "",
          },

          // emailConfirmed: userData.emailConfirmed || false,
          // isActive: userData.isActive || false,
          tourOperator: initialTourOperator,
          destination: initialDestination,
          roles: initialRoles,
        });

        setSelectedRoles(
          userData.roles
            ? userData.roles.map((role) => ({
              roleName: role.roleName,
              description: role.description,
            }))
            : []
        );
        setSelectedDestination(initialDestination);
        setSelectedTourOperator(initialTourOperator);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }

    };

    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  // Handlers for roles, destination, and tour operator changes
  const handleRoleChange = (event, newValue) => {
    setSelectedRoles(newValue);
    formik.setFieldValue("roles", newValue.map((role) => role.roleName));
  };
  const handleDestinationChange = (event, newValue) => {
    setSelectedDestination(newValue);
    formik.setFieldValue("destination", newValue);
  };
  const handleTourOperatorChange = (event, newValue) => {
    setSelectedTourOperator(newValue);
    formik.setFieldValue("tourOperator", newValue);
  };

  return (
    <Dialog open={open} onClose={handleClose} >
      {/* fullWidth maxWidth="md" */}
      <DialogTitle>Edit User Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update the necessary fields below to edit user details.
        </DialogContentText>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} mt={1}>
              {/* Username */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid> */}

              {/* Full Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="fullName"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              {/* Gender */}
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={genderList}
                  value={formik.values.gender}
                  onChange={(event, value) => formik.setFieldValue("gender", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Gender"
                      name="gender"
                      onBlur={formik.handleBlur}
                      error={formik.touched.gender && Boolean(formik.errors.gender)}
                      helperText={formik.touched.gender && formik.errors.gender}
                    />
                  )}
                />
              </Grid>

              {/* Phone Number */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </Grid>

              {/* Birth Date */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Birth Date"
                  name="birthDate"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.birthDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                  helperText={formik.touched.birthDate && formik.errors.birthDate}
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  multiline
                  rows={4}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              {/* Address Fields */}
              <Grid item xs={12} mt={2}>
                <Typography variant="h6">Address Details</Typography>
              </Grid>

              {/* Country */}
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={countrylist}
                  value={formik.values.address?.country}
                  onChange={(event, value) =>
                    formik.setFieldValue("address.country", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      name="address.country"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address?.country &&
                        Boolean(formik.errors.address?.country)
                      }
                      helperText={
                        formik.touched.address?.country && formik.errors.address?.country
                      }
                    />
                  )}
                />
              </Grid>

              {/* City */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="City"
                  {...formik.getFieldProps("address.city")}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>


              {/* Sub City */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Sub City"
                  name="address.subCity"
                  value={formik.values.address?.subCity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address?.subCity &&
                    Boolean(formik.errors.address?.subCity)
                  }
                  helperText={
                    formik.touched.address?.subCity && formik.errors.address?.subCity
                  }
                />
              </Grid>

              {/* Woreda */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Woreda"
                  name="address.woreda"
                  value={formik.values.address?.woreda}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address?.woreda &&
                    Boolean(formik.errors.address?.woreda)
                  }
                  helperText={
                    formik.touched.address?.woreda && formik.errors.address?.woreda
                  }
                />
              </Grid>

              {/* House Number */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="House Number"
                  name="address.houseNumber"
                  value={formik.values.address?.houseNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address?.houseNumber &&
                    Boolean(formik.errors.address?.houseNumber)
                  }
                  helperText={
                    formik.touched.address?.houseNumber &&
                    formik.errors.address?.houseNumber
                  }
                />
              </Grid>

              {/* Destination Select */}
              <Grid item xs={12} md={6}>
                <DestinationSelect
                  selectedDestination={selectedDestination}
                  handleDestinationChange={handleDestinationChange}
                  setSelectedDestination={setSelectedDestination}
                />
              </Grid>

              {/* Tour Operator Select */}
              <Grid item xs={12} md={6}>
                <TourOperatorSelect
                  selectedTourOperator={selectedTourOperator}
                  handleTourOperatorChange={handleTourOperatorChange}
                  setSelectedTourOperator={setSelectedTourOperator}
                />
              </Grid>

              {/* Roles Selection */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    id="roles"
                    options={rolesOptions}
                    getOptionLabel={(option) => option.roleName}
                    isOptionEqualToValue={(option, value) =>
                      option.roleName === value.roleName
                    }
                    value={selectedRoles}
                    onChange={handleRoleChange}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.roleName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Roles" />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.roleName}
                          label={option.roleName}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </FormControl>
              </Grid>

              {/* Email Confirmed & Is Active */}
              {/* <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.emailConfirmed}
                        onChange={formik.handleChange}
                        name="emailConfirmed"
                      />
                    }
                    label="Email Confirmed"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isActive}
                        onChange={formik.handleChange}
                        name="isActive"
                      />
                    }
                    label="Is Active"
                  />
                </FormGroup>
              </Grid> */}
            </Grid>

            {/* Dialog Actions */}
            <DialogActions sx={{ mt: 2 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#03930a",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#027c08",
                  },
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Update User"}
              </Button>
            </DialogActions>
          </form>
        )}
      </DialogContent>
      <ToastContainer position="top-right" />
      {/* autoClose={3000} */}
    </Dialog>
  );
}
