import React from "react"
export const columns = [
   { accessorKey: "employeeId",
   header: "ID", 
   enableEditing: false,
   size: 80,
  },
 

   {
     accessorKey: "firstName",
     header: "First Name",

   },
   {
     accessorKey: "middleName",
     header: "Middle Name",

   },
   {
     accessorKey: "lastName",
     header: "Last Name",

   },
   {
     accessorKey: "email",
     header: "Email",

   },
   {
     accessorKey: "phoneNum",
     header: "Phone Number",

   },
   {
     accessorKey: "address",
     header: "Address",

   },
   {
    accessorKey: 'gender',
    header: 'Gender',
    Cell: ({ cell }) => {
      const gender = cell.getValue() || '';
      return <p>{gender}</p>;
    },
    editVariant: 'select',
    editSelectOptions: ['Male', 'Female', 'Others'],
    muiEditTextFieldProps: {
      select: true
    }
  },
  //  {
  //    accessorKey: "office",
  //    header: "Work Office",

  //  },

//  {
//      accessorKey: 'visits',
//      header: 'Destinations, Duration of Stay',
//      Cell: ({ cell }) => {
//        const visits = cell.getValue() || [];
 
//        if (visits.length > 0) {
//          const visitDetails = visits.map((visit) => (
//            `${visit.destination.name}, Stayed for ${visit.durationOfStay} days`
//          ));
//          return <p>{visitDetails.join(', ')}</p>;
//        } else {
//          return <p>No destination information available</p>;
//        }
//      },
//    },
 ];