import React from "react";
import MDataTable from "../MDataTable";

import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { columns } from "./columns";
import useDeleteData from "../hooks/useDeleteData";
import usePutData from "../hooks/usePutData";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";


function Destinations() {
  const navigate = useNavigate()
  const [rowSelection, setRowSelection] = React.useState({})

  // const id = Object.keys(rowSelection).filter((key) => rowSelection[key])
  console.log('ss', rowSelection)
  const [destinationId, setDestinationId] = React.useState(null);

  let endpoint = `/destination/registerDestination`;
  let getEndpoint = `/destination/getDestinations`;
  let deleteEndpoint = `/destination/delete/destination`;
  let editEndpoint = `/destination/edit`;


  const {
    data: response,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    isFetching: isFetchingTax,
    error: errorGet,
  } = useGetData(getEndpoint);

  const { mutate, isLoading, isError, data, isSuccess } = usePostData(endpoint);
  const handleNewAdd = async ({ values, table }) => {
    mutate(values);

    table.setCreatingRow(null);
  };

  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: setSuccessUpdate,
    error: errorupdate,
  } = usePutData(editEndpoint, destinationId, data);

  const handleEdit = async ({ row, values, table }) => {
    update({
      endpoint: editEndpoint,
      Id: row.original.destinationId,
      data: values,
    });
    table.setEditingRow(null);
  };

  const { mutateAsync: deleteData, isPending: isDeleting } = useDeleteData(deleteEndpoint, destinationId);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this Destination?")) {
      deleteData({ endpoint: deleteEndpoint, Id: row.original.destinationId });
    }
  };

  const handleViewClick = (rowData) => {
    navigate('/dashboard/destinantion/details', { state: { rowData } })
  }

  return (
    <div>
      <MDataTable
        headerTitle="Destinations"
        add="Destination"
        openDeleteConfirmModal={openDeleteConfirmModal}
        handleNewAdd={handleNewAdd}
        handleEdit={handleEdit}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        enableRowSelection={true}
        editable={true}
        columns={columns}
        data={response || []}
        isLoading={isLoadingGet}
        isError={isErrorGet}
        title={"Destination"}
        handleViewClick={handleViewClick}
      />
      <ToastContainer position="top-center" />

    </div>
  );
}
export default Destinations;
