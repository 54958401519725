
import React from "react"
export const columns =  [
    {
        accessorKey: 'bankId',
        header: 'ID',
        enableEditing: false,
        size: 80,
      },    
      {
        accessorKey: 'name',
        header: 'Branch Name'
        
      },
      {
        accessorKey: 'address',
        header: 'bank_Address',
      },

      {
        accessorKey: 'latitude',
        header: 'branch_Latitude',
      },    
      {
        accessorKey: 'longitude',
        header: 'branch_Longitude',
      },
      {
        accessorKey: 'description',
        header: 'bank_Description',
      },
      {
        accessorKey: 'destination.name',
        header: 'Bank_Destination',
      

      // Cell: ({ cell }) => <p>{cell.getValue()[0].destination.name}</p>,
      // Cell: ({ cell }) => <p>{console.log('sss',cell.getValue()[0]?.destination.name)}</p>,
    }
  ]

 