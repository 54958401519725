import React from "react";
import MDataTable from "../MDataTable";

import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { columns } from "./columns";
import useDeleteData from "../hooks/useDeleteData";
import usePutData from "../hooks/usePutData";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Hotels() {
  const [hotelId, setHotelId] = React.useState(null);

  let endpoint = `/hotel/registerHotel`;
  let getEndpoint = `/hotel/getHotels`;
  let deleteEndpoint = `/hotel/delete/hotel`;
  let editEndpoint = `/hotel/edit`;

  const {
    data: response,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    isFetching: isFetchingTax,
    error: errorGet,
  } = useGetData(getEndpoint);

  const { mutate, isLoading, isError, data, isSuccess } = usePostData(endpoint);
  const handleNewAdd = async ({ values, table }) => {
    mutate(values);
    table.setCreatingRow(null);
  };

  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: setSuccessUpdate,
    error: errorupdate,
  } = usePutData(editEndpoint,hotelId, data);

  const handleEdit = async ({ row, values, table }) => {
    update({
      endpoint: editEndpoint,
      Id: row.original.hotelId,
      data: values,
    });
    table.setEditingRow(null);
  };

  const { mutateAsync: deleteData, isPending: isDeleting } = useDeleteData(deleteEndpoint, hotelId);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this Hotel?")) {
      deleteData({ endpoint: deleteEndpoint, Id: row.original.hotelId });
    }
  };
  const navigate = useNavigate();
  const handleViewClick = (rowData) => {
    navigate('/dashboard/hotel/details', { state: { rowData } })
  }

  return (
    <div>
      <MDataTable
        headerTitle="Hotels"
        add="Hotel"
        openDeleteConfirmModal={openDeleteConfirmModal}
        handleNewAdd={handleNewAdd}
        handleEdit={handleEdit}
        editable={true}
        columns={columns}
        data={response || []}
        isLoading={isLoadingGet}
        isError={isErrorGet}
        title={"Hotel"}
        handleViewClick={handleViewClick}
      />
            <ToastContainer position="top-center" />

    </div>
  );
}
export default Hotels;
