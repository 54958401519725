import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReportCard from "../ReportCard";
import ApexChart from "../DashboardGraph";
import PieChart from "../PieChart";
import { BACKEND_API } from "../../utils/API";
import jwt from "jsonwebtoken";

const GET_CARD_DATA_ENDPOINT = `/report/getCardData`;
const GET_GRAPH_DATA_ENDPOINT = `/tourist/get-tourist-graph-data`;
const GET_AGE_RANGE_ENDPOINT = `/tourist/getAgeRangeCount`;
const GET_GENDER_BASED_ENDPOINT = `/tourist/findFemaleAndMaleCount`;
const GET_TOURIST_BASED_ENDPOINT = `/tourist/findInternationalAndDemoCount`;

const AGE_RANGES = [
  { start: 0, end: 29 },
  { start: 30, end: 45 },
  { start: 46, end: 60 },
  { start: 61, end: 200 },
];

const Reports = () => {
  const [cardData, setCardData] = useState({});
  const [graphData, setGraphData] = useState(null);
  const [ageRangePieChartData, setAgeRangePieChartData] = useState([]);
  const [genderBasedPieChartData, setGenderBasedPieChartData] = useState([]);
  const [touristBasedPieChartData, setTouristBasedPieChartData] = useState([]);
  const { destinations, packages, tourOperators, tourists, employee } =
    cardData;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getData();
      await getGraphData();
      await getAgeRangePieChartData();
      await getGenderBasedPieChart();
      await getTouristBasedPieChartData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getData = async () => {
    const response = await BACKEND_API.get(GET_CARD_DATA_ENDPOINT);
    setCardData(response.data);
  };

  const getGraphData = async () => {
    const response = await BACKEND_API.get(GET_GRAPH_DATA_ENDPOINT);
    setGraphData(response.data);
  };

  const getAgeRangePieChartData = async () => {
    const response = await BACKEND_API.post(GET_AGE_RANGE_ENDPOINT, AGE_RANGES);
    setAgeRangePieChartData(response.data);
  };

  const getGenderBasedPieChart = async () => {
    const response = await BACKEND_API.get(GET_GENDER_BASED_ENDPOINT);
    setGenderBasedPieChartData([
      response.data.male_count,
      response.data.female_count,
    ]);
  };

  const getTouristBasedPieChartData = async () => {
    const response = await BACKEND_API.get(GET_TOURIST_BASED_ENDPOINT);
    setTouristBasedPieChartData([
      response.data.demostic_count,
      response.data.international_count,
    ]);
  };

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    // Get the token from localStorage
    const token = localStorage.getItem("token");

    // Decode the token to get user roles
    try {
      const decodedToken = jwt.decode(token);
      // Assuming roles are stored in the 'roles' claim
      const roles = decodedToken.roles || [];
      setUserRoles(roles);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }, []);
  
  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('token');
});

  return (
    <div style={{}}>
      {destinations && userRoles.includes("System Admin") && (
        <Box>
          <Grid container sx={{ mt: 2 }} spacing={6}>
            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={tourists.title}
                value={tourists.value}
                cardBackground={"#44804A"}
                titleBackground={"#60A66A"}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={destinations.title}
                value={destinations.value}
                cardBackground={"#009CDF"}
                titleBackground={"#05648B"}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={packages.title}
                value={packages.value}
                cardBackground={"#C81810"}
                titleBackground={"#B25E5E"}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={employee.title}
                value={employee.value}
                cardBackground={"#F7C913"}
                titleBackground={"#9DA450"}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={tourOperators.title}
                value={tourOperators.value}
                cardBackground={"#373737"}
                titleBackground={"#5B5B5B"}
              />
            </Grid>

            <Grid item xs={12}>
              {graphData && <ApexChart graphData={graphData} />}
            </Grid>
            <Grid item xs={12} container justifyContent={"space-around"}>
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography>Age Range</Typography>
                {ageRangePieChartData && (
                  <PieChart
                    pieChartData={ageRangePieChartData}
                    label={["0-29", "30-45", "46-60", ">=61"]}
                  />
                )}
              </Stack>
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography>Gender </Typography>
                {genderBasedPieChartData && (
                  <PieChart
                    pieChartData={genderBasedPieChartData}
                    label={["Male", "Female"]}
                  />
                )}
              </Stack>
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography>Tourist Type </Typography>
                {touristBasedPieChartData && (
                  <PieChart
                    pieChartData={touristBasedPieChartData}
                    label={["Demostic", "International"]}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}

      {destinations && userRoles.includes("admin") && (
        <Box>
          <Grid container sx={{ mt: 2 }} spacing={6}>
            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={tourists.title}
                value={tourists.value}
                cardBackground={"#44804A"}
                titleBackground={"#60A66A"}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={packages.title}
                value={packages.value}
                cardBackground={"#C81810"}
                titleBackground={"#B25E5E"}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={employee.title}
                value={employee.value}
                cardBackground={"#F7C913"}
                titleBackground={"#9DA450"}
              />
            </Grid>

            <Grid item xs={12} md={2.4}>
              <ReportCard
                title={tourOperators.title}
                value={tourOperators.value}
                cardBackground={"#373737"}
                titleBackground={"#5B5B5B"}
              />
            </Grid>

            <Grid item xs={12}>
              {graphData && <ApexChart graphData={graphData} />}
            </Grid>
            <Grid item xs={12} container justifyContent={"space-around"}>
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography>Age Range</Typography>
                {ageRangePieChartData && (
                  <PieChart
                    pieChartData={ageRangePieChartData}
                    label={["0-29", "30-45", "46-60", ">=61"]}
                  />
                )}
              </Stack>
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography>Gender </Typography>
                {genderBasedPieChartData && (
                  <PieChart
                    pieChartData={genderBasedPieChartData}
                    label={["Male", "Female"]}
                  />
                )}
              </Stack>
              <Stack direction={"column"} spacing={2} alignItems={"center"}>
                <Typography>Tourist Type </Typography>
                {touristBasedPieChartData && (
                  <PieChart
                    pieChartData={touristBasedPieChartData}
                    label={["Demostic", "International"]}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default Reports;
