import React from "react";
import MDataTable from "../MDataTable";

import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { columns } from "./columns";
import useDeleteData from "../hooks/useDeleteData";
import usePutData from "../hooks/usePutData";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Offices() {
  const [officeId, setOfficeId] = React.useState(null);

  let endpoint = `/office/registerOffice`;
  let getEndpoint = `/office/getOffices`;
  let deleteEndpoint = `/office/delete/office`;
  let editEndpoint = `/office/edit`;

  const {
    data: response,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    isFetching: isFetchingTax,
    error: errorGet,
  } = useGetData(getEndpoint);

  const { mutate, isLoading, isError, data, isSuccess } = usePostData(endpoint);
  const handleNewAdd = async ({ values, table }) => {
    mutate(values);
    table.setCreatingRow(null);
  };

  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: setSuccessUpdate,
    error: errorupdate,
  } = usePutData(editEndpoint,officeId, data);

  const handleEdit = async ({ row, values, table }) => {
    update({
      endpoint: editEndpoint,
      Id: row.original.officeId,
      data: values,
    });
    table.setEditingRow(null);
  };

  const { mutateAsync: deleteData, isPending: isDeleting } = useDeleteData(deleteEndpoint, officeId);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this Office?")) {
      deleteData({ endpoint: deleteEndpoint, Id: row.original.officeId });
    }
  };
const navigate = useNavigate();
const handleViewClick = (rowData) => {
  navigate('/dashboard/office-detail', { state: { rowData } })
}

  return (
    <div>
      <MDataTable
        headerTitle="Offices"
        add="Office"
        openDeleteConfirmModal={openDeleteConfirmModal}
        handleNewAdd={handleNewAdd}
        handleEdit={handleEdit}
        editable={true}
        columns={columns}
        data={response || []}
        isLoading={isLoadingGet}
        isError={isErrorGet}
        title={"Office"}
        handleViewClick ={handleViewClick}
      />
            <ToastContainer position="top-center" />

    </div>
  );
}
export default Offices;
