import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyle";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {Divider} from "@mui/material";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
        <Divider
        sx={{ borderColor: "text.main", borderWidth: "1px", marginY: 2 }}
      />
      {"Copyright © "}
      <Link color="inherit" href="#">
        Visit Oromia
      </Link>{" "}

      {/* <Link color="inherit" href="#">
        Visit Ethiopia
      </Link>{" "} */}
      {new Date().getFullYear()}
         
      {",  All rights reserved!"}
    </Typography>
  );
}


const Footer = () => {
  return (
    <Box
    sx={{ padding: 3, backgroundColor: "info.main", marginTop: 3 }}
    id="footer"
  >

 <h1 style={{ color: '#443f3f', textAlign: 'center', marginTop: '-50px' }}>
        <span style={{ color: '#C81810' }}>Visit-</span>Oromia
      </h1>
      
      {/* <h1 style={{ color: '#804000', textAlign: 'center', marginTop: '-50px' }}>
        <span style={{ color: '#00897B' }}>Visit-</span>Ethiopia
      </h1> */}

      <Container>
        <Row>
          <Column>
            <Heading>About Us</Heading>
            <FooterLink href="https://www.oromiatourismcommission.et/?page_id=3251">Mission</FooterLink>
            <FooterLink href="https://www.oromiatourismcommission.et/?page_id=3251">Vision</FooterLink>
            <FooterLink href="https://www.visitoromia.org">Background</FooterLink>
          </Column>
          <Column>
            <Heading>Services</Heading>
            <FooterLink href="https://www.weareradiant.media/360/otc/index.htm">Visit Office Virtually</FooterLink>
            <FooterLink href="https://chatting.page/5x9f8ujwxrtemvzzvynqzu0rr0gnvfgp">Live chat</FooterLink>
            <FooterLink href="https://t.me/visit_oromia_chat_bot">Telegram Chat Bot</FooterLink>

            <FooterLink href="https://www.tourhq.com/ethiopia/addis-ababa-guides/120">Contact Tourguides</FooterLink>
            <FooterLink href="https://www.google.com/maps/place/Oromia+Tourism+Commission/@8.9906342,38.7838934,15z/data=!4m2!3m1!1s0x0:0x42881f1e7f1fe151?sa=X&ved=2ahUKEwjJkfbA1q-DAxUxhf0HHUiCAOUQ_BJ6BAgLEAA&cshid=1703682235273679">Find places</FooterLink>
          </Column>
          <Column>
            <Heading>Contact Us</Heading>
            <FooterLink href="https://www.pinterest.com/Visit_oromia/_created">pinterest</FooterLink>
            <FooterLink href="https://t.me/visit_oromia">Telegram Channel</FooterLink>
            <FooterLink href="https://www.facebook.com/visitoromia">Facebook</FooterLink>
            <FooterLink href="https://www.linkedin.com/uas/login-submit">LinkedIn</FooterLink>
          </Column>
          <Column>
            <Heading>Social Media</Heading>
            <FooterLink href="https://www.facebook.com/visitoromia">
              <i className="fab fa-facebook-f">
                <span style={{ marginLeft: "10px" }}>Facebook</span>
              </i>
            </FooterLink>
            <FooterLink href="https://www.instagram.com/VisitOromia">
              <i className="fab fa-instagram">
                <span style={{ marginLeft: "10px" }}>Instagram</span>
              </i>
            </FooterLink>
            <FooterLink href="https://twitter.com/VisitOromia">
              <i className="fab fa-twitter">
                <span style={{ marginLeft: "10px" }}>Twitter</span>
              </i>
            </FooterLink>
            <FooterLink href="https://www.youtube.com/c/VisitOromia">
              <i className="fab fa-youtube">
                <span style={{ marginLeft: "10px" }}>Youtube</span>
              </i>
            </FooterLink>
          </Column>
        </Row>
        <Copyright/>
      </Container>
    </Box>
  );
};
export default Footer;
