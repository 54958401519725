import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Autocomplete
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import SelectOffice from "./SelectOffice";

export default function UpdateEmployeeDetail({ open, handleClose, employeeId}) {
    const [loading, setLoading] = useState(false);
    const [selectedOffice, setSelectedOffice] = useState([]);

    const [initialValues, setInitialValues] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNum: "",
        address: "",
        gender: "",
        birthDate: "",
        office: [], // Add departmentName here
    });

    const orgValidationSchema = yup.object({
        firstName: yup.string().required("First Name required"),
        middleName: yup.string().required("mMddle Name Name required"),
        lastName: yup.string().required("Last Name Name required"),

        email: yup
            .string()
            .email("Invalid  email")
            .required(" Email required"),
        phoneNum: yup.string().required("Phone number required"),
        address: yup.string().required("address required"),
        gender: yup.string().required("gender required"),
        birthDate: yup.string().required("Birth date required"),

        office: yup
            .array()
            .of(
                yup.object({
                    officeId: yup.string().required(),
                    name: yup.string().required(),
                })
            )
            .required("At least one office is required"),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: orgValidationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const response = await BACKEND_API.put(
                    `/employee/edit/${employeeId}`,
                    {
                        ...values,
                        office: selectedOffice
                    }
                );
                if (response.status === 200 || response.status === 201) {
                    toast.success(
                        response?.data?.message || "Employee updated successfully!"
                    );
                    handleClose();
                }
            } catch (error) {
                toast.error(error?.response?.data?.message || "Network error...");
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await BACKEND_API.get(
                    `/employee/getEmployee/${employeeId}`
                );
                const userData = response.data;
                const initialOffice = userData.office.map((office) => ({
                    name: office.name,
                    officeId: office.officeId,
                }));
            console.log("initialOffice", initialOffice)

                setInitialValues({
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    email: userData.email,
                    phoneNum: userData.phoneNum,
                    address: userData.address,
                    gender: userData.gender,
                    birthDate: userData.birthDate,
                    office: initialOffice,
                });

                setSelectedOffice(initialOffice);
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        if (employeeId) {
            fetchEmployeeDetails();
        }
        // fetchDepartments();
    }, [employeeId]);



    const handleOfficeChange = (event, newValue) => {
        setSelectedOffice(newValue);
        formik.setFieldValue("office", newValue);
    };

const genderList = ["Male", "Female", "Others"];
    return (          // department: userData.department,

        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update Employee Detail</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please update the Employee information and select office.
                </DialogContentText>
                <Box component={Paper} p={3} mt={2}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    {...formik.getFieldProps("firstName")}
                                    error={
                                        formik.touched.firstName &&
                                        Boolean(formik.errors.firstName)
                                    }
                                    helperText={
                                        formik.touched.firstName &&
                                        formik.errors.firstName
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Middle Name"
                                    {...formik.getFieldProps("middleName")}
                                    error={
                                        formik.touched.middleName &&
                                        Boolean(formik.errors.middleName)
                                    }
                                    helperText={
                                        formik.touched.middleName &&
                                        formik.errors.middleName
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    {...formik.getFieldProps("lastName")}
                                    error={
                                        formik.touched.lastName &&
                                        Boolean(formik.errors.lastName)
                                    }
                                    helperText={
                                        formik.touched.lastName &&
                                        formik.errors.lastName
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" Email"
                                    {...formik.getFieldProps("email")}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    {...formik.getFieldProps("phoneNum")}
                                    error={
                                        formik.touched.phoneNum &&
                                        Boolean(formik.errors.phoneNum)
                                    }
                                    helperText={
                                        formik.touched.phoneNum &&
                                        formik.errors.phoneNum
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    {...formik.getFieldProps("address")}
                                    error={
                                        formik.touched.address &&
                                        Boolean(formik.errors.address)
                                    }
                                    helperText={
                                        formik.touched.address &&
                                        formik.errors.address
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    label="birth date"
                                    {...formik.getFieldProps("birthDate")}
                                    error={
                                        formik.touched.birthDate && Boolean(formik.errors.birthDate)
                                    }
                                    helperText={
                                        formik.touched.birthDate && formik.errors.birthDate
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    fullWidth
                                    options={genderList}
                                    value={formik.values.gender}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("gender", newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Gender"
                                            error={
                                                formik.touched.gender && Boolean(formik.errors.gender)
                                            }
                                            helperText={
                                                formik.touched.gender && formik.errors.gender
                                            }
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <SelectOffice
                                    handleOfficeChange={handleOfficeChange}
                                    selectedOffice={selectedOffice}
                                    setSelectedOffice={setSelectedOffice}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "red",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#d32f2f",
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#03930a",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#027c08",
                                    },
                                }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress size={20} sx={{ color: "#FFF" }} />
                                ) : (
                                    "Update Employee Detail"
                                )}
                            </Button>
                        </DialogActions>
                    </form>
                </Box>
                <ToastContainer position="top-center" />
            </DialogContent>
        </Dialog>
    );
}
