import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import HotelIcon from "@mui/icons-material/Hotel";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Hiking from "@mui/icons-material/Hiking";
import CardGiftcard from "@mui/icons-material/CardGiftcard";
import Person from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
const ROLES = {
  ADMIN: "admin",
  SYSTEM_ADMIN: "System Admin",
};
const menus = [
  {
    index: 0,
    link: "/dashboard/reports",
    title: "Reports",
    icon: <DashboardIcon />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },
  {
    index: 1,
    link: "/dashboard/destinantion",
    title: "Destination",
    icon: <LocationOnIcon />,
    roles: [ROLES.SYSTEM_ADMIN],
  },
  {
    index: 2,
    link: "/dashboard/tourist",
    title: "Tourist",
    icon: <PeopleIcon />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },
  {
    index: 3,
    link: "/dashboard/hotels",
    title: "Hotels",
    icon: <HotelIcon />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },
  {
    index: 4,
    link: "/DashBoard/banks",
    title: "Banks",
    icon: <Person />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },
  {
    index: 5,
    link: "/dashboard/offices",
    title: "Offices",
    icon: <BusinessCenterIcon />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },
  {
    index: 6,
    link: "/dashboard/userManagement",
    title: "User management",
    icon: <ManageAccountsIcon />,
    roles: [ROLES.SYSTEM_ADMIN],
  },
  {
    index: 7,
    link: "/dashboard/tourOperator",
    title: "Tour Operator",
    icon: <Hiking />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },
  {
    index: 8,
    link: "/dashboard/packages",
    title: "Packages",
    icon: <CardGiftcard />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },
  {
    index: 9,
    link: "/dashboard/employee",
    title: "Employee",
    icon: <Person />,
    roles: [ROLES.ADMIN, ROLES.SYSTEM_ADMIN],
  },

];

function ListItems({ userRoles }) {
  const [filteredMenus, setFilteredMenus] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    // Filter menus based on user roles
    const allowedMenus = menus.filter((menu) =>
      menu.roles.some((requiredRole) => userRoles.includes(requiredRole))
    );

    // Find the index of the current path in the allowedMenus
    const currentIndex = allowedMenus.findIndex((menu) => menu.link === currentPath);

    // Update state
    setFilteredMenus(allowedMenus);
    setSelectedIndex(currentIndex >= 0 ? currentIndex : 0);
  }, [currentPath, userRoles]);

  const handleClick = (event, index) => {
    setSelectedIndex(index);
  };

  return filteredMenus.map((menu, key) => (
    <ListItemButton
      key={key}
      sx={{
        "&.Mui-selected": {
          color: "#488550",
          backgroundColor: "#FFF",
          borderRadius: "21.5px",
        },
      }}
      selected={selectedIndex === key}
      onClick={(event) => {
        const index = menu.index;
        navigate(menu.link);
        handleClick(event, index);
      }}
    >
      <ListItemIcon sx={{ color: selectedIndex === key && "#488550" }}>
        {menu.icon}
      </ListItemIcon>
      <ListItemText primary={menu.title} />
    </ListItemButton>
  ));
}

export default ListItems;
