   import React from "react"
   export const columns = [
      { accessorKey: "touristId",
      header: "ID", 
      enableEditing: false,
        size: 80,
    },
      {
        accessorKey: "fullName",
        header: "full Name",
      
      },
      {
        accessorKey: "country",
        header: "Tourist Nationality",
        Cell: ({ cell }) => {
          const country = cell.getValue() || '';
          return <p>{country}</p>;
        },
        editVariant: 'select',
        editSelectOptions: [
          "Afghanistan",
          "Albania",
          "Algeria",
          "Andorra",
          "Angola",
          "Antigua and Barbuda",
          "Argentina",
          "Armenia",
          "Australia",
          "Austria",
          "Azerbaijan",
          "Bahamas",
          "Bahrain",
          "Bangladesh",
          "Barbados",
          "Belarus",
          "Belgium",
          "Belize",
          "Benin",
          "Bhutan",
          "Bolivia",
          "Bosnia and Herzegovina",
          "Botswana",
          "Brazil",
          "Brunei",
          "Bulgaria",
          "Burkina Faso",
          "Burundi",
          "Cabo Verde",
          "Cambodia",
          "Cameroon",
          "Canada",
          "Central African Republic",
          "Chad",
          "Chile",
          "China",
          "Colombia",
          "Comoros",
          "Congo",
          "Costa Rica",
          "Croatia",
          "Cuba",
          "Cyprus",
          "Czechia",
          "Denmark",
          "Djibouti",
          "Dominica",
          "Dominican Republic",
          "Ecuador",
          "Egypt",
          "El Salvador",
          "Equatorial Guinea",
          "Eritrea",
          "Estonia",
          "Eswatini",
          "Ethiopia",
          "Fiji",
          "Finland",
          "France",
          "Gabon",
          "Gambia",
          "Georgia",
          "Germany",
          "Ghana",
          "Greece",
          "Grenada",
          "Guatemala",
          "Guinea",
          "Guinea-Bissau",
          "Guyana",
          "Haiti",
          "Honduras",
          "Hungary",
          "Iceland",
          "India",
          "Indonesia",
          "Iran",
          "Iraq",
          "Ireland",
          "Israel",
          "Italy",
          "Jamaica",
          "Japan",
          "Jordan",
          "Kazakhstan",
          "Kenya",
          "Kiribati",
          "Korea, North",
          "Korea, South",
          "Kosovo",
          "Kuwait",
          "Kyrgyzstan",
          "Laos",
          "Latvia",
          "Lebanon",
          "Lesotho",
          "Liberia",
          "Libya",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Madagascar",
          "Malawi",
          "Malaysia",
          "Maldives",
          "Mali",
          "Malta",
          "Marshall Islands",
          "Mauritania",
          "Mauritius",
          "Mexico",
          "Micronesia",
          "Moldova",
          "Monaco",
          "Mongolia",
          "Montenegro",
          "Morocco",
          "Mozambique",
          "Myanmar",
          "Namibia",
          "Nauru",
          "Nepal",
          "Netherlands",
          "New Zealand",
          "Nicaragua",
          "Niger",
          "Nigeria",
          "North Macedonia",
          "Norway",
          "Oman",
          "Pakistan",
          "Palau",
          "Palestine",
          "Panama",
          "Papua New Guinea",
          "Paraguay",
          "Peru",
          "Philippines",
          "Poland",
          "Portugal",
          "Qatar",
          "Romania",
          "Russia",
          "Rwanda",
          "Saint Kitts and Nevis",
          "Saint Lucia",
          "Saint Vincent and the Grenadines",
          "Samoa",
          "San Marino",
          "Sao Tome and Principe",
          "Saudi Arabia",
          "Senegal",
          "Serbia",
          "Seychelles",
          "Sierra Leone",
          "Singapore",
          "Slovakia",
          "Slovenia",
          "Solomon Islands",
          "Somalia",
          "South Africa",
          "South Sudan",
          "Spain",
          "Sri Lanka",
          "Sudan",
          "Suriname",
          "Sweden",
          "Switzerland",
          "Syria",
          "Taiwan",
          "Tajikistan",
          "Tanzania",
          "Thailand",
          "Timor-Leste",
          "Togo",
          "Tonga",
          "Trinidad and Tobago",
          "Tunisia",
          "Turkey",
          "Turkmenistan",
          "Tuvalu",
          "Uganda",
          "Ukraine",
          "United Arab Emirates",
          "United Kingdom",
          "United States",
          "Uruguay",
          "Uzbekistan",
          "Vanuatu",
          "Vatican City",
          "Venezuela",
          "Vietnam",
          "Yemen",
          "Zambia",
          "Zimbabwe",
        ],
        muiEditTextFieldProps: {
          select: true
        }
        // width: 150,
        // editable: true,
      },




      {
        accessorKey: 'gender',
        header: 'Gender',
        Cell: ({ cell }) => {
          const gender = cell.getValue() || '';
          return <p>{gender}</p>;
        },
        editVariant: 'select',
        editSelectOptions: ['Male', 'Female', 'Others'],
        muiEditTextFieldProps: {
          select: true
        }
      },
      

      {
        accessorKey: "city",
        header: "Tour City",

      },

      
      {
        accessorKey: "touristType",
        header: "tourist Type",

        Cell: ({ cell }) => {
          const gender = cell.getValue() || '';
          return <p>{gender}</p>;
        },
        editVariant: 'select',
        editSelectOptions: ['Demostic', 'International'],
        muiEditTextFieldProps: {
          select: true
        }

      },
      // {
      //   accessorKey: "birthDate",
      //   header: "birth Date",

      // },
      {
        accessorKey: "email",
        header: "Email",

      },
      {
        accessorKey: "phoneNum",
        header: "phone Number",

      },
      // {
      //   accessorKey: "firstVisitedDate",
      //   header: "First Visited Date",

      // },
      {
        accessorKey: "passportId",
        header: "passport ID",

      },
      // {
      //   accessorKey: "spendAmount",
      //   header: "Spend Amount",

      // },

    // {
    //     accessorKey: 'visits',
    //     header: 'Destinations, Duration of Stay',
    //     Cell: ({ cell }) => {
    //       const visits = cell.getValue() || [];
    
    //       if (visits.length > 0) {
    //         const visitDetails = visits.map((visit) => (
    //           `${visit.destination.name}, Stayed for ${visit.durationOfStay} days`
    //         ));
    //         return <p>{visitDetails.join(', ')}</p>;
    //       } else {
    //         return <p>No destination information available</p>;
    //       }
    //     },
    //   },
    ];