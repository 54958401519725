import React from "react"
export const columns = [
   { accessorKey: "tourOperatorId",
   header: "ID",  
   enableEditing: false,
   size: 80,
  },
   {
     accessorKey: "ownerFullName",
     header: "Owner fullName",
   
   },
   {
     accessorKey: "tourOrgName",
     header: "Organization Name",
     // width: 150,
     // editable: true,
   },
   {
     accessorKey: "ownerAddress",
     header: "Owner Address",

   },

   {
     accessorKey: "email",
     header: "Email",

   },
   {
     accessorKey: "phoneNum",
     header: "Phone Number",

   },
  //  {
  //    accessorKey: "tourCategory",
  //    header: "Tour category",

  //  },
  //  {
  //    accessorKey: "touristType",
  //    header: "Tourist Type",

  //  },
  //  {
  //    accessorKey: "foundAt",
  //    header: "Found At",

  //  },
   {
     accessorKey: "tinNum",
     header: "Tin",

   },
  //  {
  //    accessorKey: "destinations",
  //    header: "Destinations",

  //  },
 

//  {
//      accessorKey: 'visits',
//      header: 'Destinations, Duration of Stay',
//      Cell: ({ cell }) => {
//        const visits = cell.getValue() || [];
 
//        if (visits.length > 0) {
//          const visitDetails = visits.map((visit) => (
//            `${visit.destination.name}, Stayed for ${visit.durationOfStay} days`
//          ));
//          return <p>{visitDetails.join(', ')}</p>;
//        } else {
//          return <p>No destination information available</p>;
//        }
//      },
//    },
 ];