
import React from "react"
export const columns =  [
    {
        accessorKey: 'destinationId',
        header: 'ID',
        enableEditing: false,
        size: 80,
      },    
      {
        accessorKey: 'name',
        header: 'Destination Name'
        
      },
      {
        accessorKey: 'address',
        header: 'Destination_Address',
      },

      {
        accessorKey: 'latitude',
        header: 'Destination_Latitude',
      },    
      {
        accessorKey: 'longitude',
        header: 'Destination_Longitude',
      },
      {
        accessorKey: 'description',
        header: 'Destination_Description',
      },
      
  ]

 