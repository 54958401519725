import { useState } from "react";
// import { AuthContext } from "src/context/AuthContext";

// const {token} = AuthContext();

const x =  localStorage.getItem('token');
window.addEventListener('beforeunload', () => {
    localStorage.removeItem('token');
});
// const [token,setToken] = useState(null);

const Constants = {
    token : x,
    black: 'black',
    green: 'green',
    white: 'white',
    whiteSmoke: 'whiteSmoke',
    cyanblue: '#00adef',
    darkblue: '#1D267D',
    lightGray: '#f5f5f5',
    // baseURL: "https://payroll-ms.onrender.com",
    baseURL: "http://10.2.125.125:4400"
}

export default Constants