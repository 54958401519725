import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { host } from "../../constants";
import {
  IconButton,
  Avatar,
  Box,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import profileUrl from "./../../assets/images/profile-iconprofileIcon.png"; // Default profile icon
import jwt from "jsonwebtoken";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";

const Profile = ({ token }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileImg, setProfileImg] = useState(profileUrl); // Default profile image
  const [userNameOrEmail, setUserNameOrEmail] = useState("Guest");
  const fileInputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [passwordChange, setPasswordChange] = useState({
    oldPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const decodedToken = jwt.decode(token);
          setUserNameOrEmail(decodedToken.sub);

          // Fetch the user profile data
          const response = await axios.get(
            `${host}/api/v1/user/getUserProfile/${decodedToken.sub}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              
            }
          );

          if (response.status === 200) {
            const imageUrl = response?.data?.imageUrl;
            setProfileImg(imageUrl); // Update the profile image with the fetched URL or default
            toast.success(response.data.message);
          } else {
            toast.error('Failed to load profile image');
          }
        } catch (error) {
          toast.error('There was an error fetching the profile data!');
        }
      }
    };

    fetchData(); // Call the async function
  }, [token]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data", // Ensure correct content type for file upload
    },
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleUpload(file);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("profileImg", file);

    try {
      const response = await axios.put(
        `${host}/api/v1/user/uploadProfileImg/${userNameOrEmail}`,
        formData,
        config
      );

      if (response.status === 200) {
        setProfileImg(response?.data?.imageUrl); // Update the profile image with the new URL
        toast.success("Profile image uploaded successfully!");
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "There was an error uploading the file!"
      );
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = (type) => {
    setDialogType(type);
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogType("");
  };
  const handleUsernameChange = async () => {
    try {
      const response = await axios.put(
        `${host}/api/v1/user/${userNameOrEmail}/true`,
        {
          newUsername,
          oldPassword: passwordChange.oldPassword,
          newPassword: passwordChange.newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        toast.success("Username and Password updated successfully,so search the page and login Again!");
        handleDialogClose();
      } else {
        toast.error("Failed to update username");
      }
    } catch (error) {
      console.error("There was an error updating the username!", error);
      toast.error(error.data.message);
    }
  };

  const handlePasswordChange = async () => {
    try {
      const response = await axios.put(
        `${host}/api/v1/user/changePassword/${userNameOrEmail}`,
        passwordChange,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        toast.success("Password changed successfully!");
        handleDialogClose();
      } else {
        toast.error("Failed to change password");
      }
    } catch (error) {
      console.error("There was an error changing the password!", error);
      toast.error("There was an error changing the password!");
    }
  };

  if (!token) {
    return (
      <Box>
        <IconButton>
          <Avatar
            src={profileUrl}
            alt="Default Profile Icon"
            style={{
              width: "34px",
              height: "34px",
            }}
          />
          <Typography
            variant="caption"
            style={{
              fontSize: "xx-small",
              marginLeft: "8px",
            }}
          >
            Guest
          </Typography>
        </IconButton>
      </Box>
    );
  }

  return (
    <Box>
      <IconButton onClick={handleMenuClick}>
        <Avatar
          src={profileImg}
          alt="Profile Icon"
          style={{
            width: "34px",
            height: "34px",
          }}
        />
        <Typography
          variant="caption" // Adjusts the text to a smaller size
          style={{
            fontSize: "xx-small", // Ensures the text size is xx-small
            marginLeft: "8px", // Adds some space between the avatar and the text
          }}
        >
          {userNameOrEmail}
        </Typography>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => fileInputRef.current.click()}>
          Update Profile Image
        </MenuItem>
        <MenuItem onClick={() => handleDialogOpen("username")}>
          Change Username
        </MenuItem>
        <MenuItem onClick={() => handleDialogOpen("password")}>
          Change Password
        </MenuItem>
      </Menu>

      <input
        type="file"
        ref={fileInputRef}
        hidden
        accept="image/*"
        onChange={handleFileChange}
      />

      <Dialog open={openDialog} onClose={handleDialogClose}>
        {dialogType === "username" && (
          <>
            <DialogTitle>Change Username</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter your new username and password to confirm the change.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="New Username"
                type="text"
                fullWidth
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Current Password"
                type="password"
                fullWidth
                value={passwordChange.oldPassword}
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    oldPassword: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                label="New Password"
                type="password"
                fullWidth
                value={passwordChange.newPassword}
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    newPassword: e.target.value,
                  })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button onClick={handleUsernameChange}>Change Username</Button>
            </DialogActions>
          </>
        )}
        {dialogType === "password" && (
          <>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter your current password and the new password.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Current Password"
                type="password"
                fullWidth
                value={passwordChange.oldPassword}
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    oldPassword: e.target.value,
                  })
                }
              />
              <TextField
                margin="dense"
                label="New Password"
                type="password"
                fullWidth
                value={passwordChange.newPassword}
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    newPassword: e.target.value,
                  })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button onClick={handlePasswordChange}>Change Password</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* <ToastContainer position="top-center" /> */}
    </Box>
  );
};

export default Profile;
