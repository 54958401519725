import React from "react";
import MDataTable from "../MDataTable";

import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { columns } from "./columns";
import useDeleteData from "../hooks/useDeleteData";
import usePutData from "../hooks/usePutData";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function UserManagements() {
  const [userId, setUserId] = React.useState(null);

  let endpoint = `/api/v1/user/createUser`;
  let getEndpoint = `/api/v1/user/getUsers`;
  let deleteEndpoint = `/api/v1/user`;
  let editEndpoint = `/api/v1/user/{userId}`;


  const {
    data: response,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    isFetching: isFetchingTax,
    error: errorGet,
  } = useGetData(getEndpoint);

  const { mutate, isLoading, isError, data, isSuccess } = usePostData(endpoint);
  const handleNewAdd = async ({ values, table }) => {
    mutate(values);

    table.setCreatingRow(null);
  };

  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: setSuccessUpdate,
    error: errorupdate,
  } = usePutData(editEndpoint, userId, data);

  const handleEdit = async ({ row, values, table }) => {
    update({
      endpoint: editEndpoint,
      Id: row.original.userId,
      data: values,
    });
    table.setEditingRow(null);
  };

  const { mutateAsync: deleteData, isPending: isDeleting } = useDeleteData(deleteEndpoint, userId);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this User?")) {
      deleteData({ endpoint: deleteEndpoint, Id: row.original.userId });
    }
  };

  const navigate = useNavigate();
  const handleViewClick = (rowData) => {
    navigate("/dashboard/user-detail", { state: { rowData } });
  };


  return (
    <div>
      <MDataTable
        headerTitle="User Managements"
        add="User Management"
        openDeleteConfirmModal={openDeleteConfirmModal}
        handleNewAdd={handleNewAdd}
        handleEdit={handleEdit}
        editable={true}
        columns={columns}
        data={response || []}
        isLoading={isLoadingGet}
        isError={isErrorGet}
        title={"User Management"}
        handleViewClick={handleViewClick}

      />
      <ToastContainer position="top-center" />

    </div>
  );
}
export default UserManagements;
