import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Autocomplete,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import { useLocation } from "react-router-dom";

export default function UpdateTourist({ open, handleClose }) {
    const location = useLocation();
    const touristId = location.state?.rowData?.touristId;

    const [loading, setLoading] = useState(false);
    const countrylist = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czechia",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, North",
        "Korea, South",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ];
    const tourCategoryList = ["water body", "mountains", "Historical place"];
    const touristTypeList = ["International", "Demostic"];


    const [initialValues, setInitialValues] = useState({
        fullName: "",
        country: "",
        city: "",
        subCity: "",
        gender: "",
        touristType: "",
        tourCategory: [],
        birthDate: "",
        email: "",
        phoneNum: "",
        passportId: "",
        zipcode: "",
        visits: [{ durationOfStay: "", }]

    });

    const orgValidationSchema = yup.object({
        fullName: yup.string().required("Full Name required"),
        country: yup.string().required("Country required"),
        city: yup.string().required("City required"),
        phoneNum: yup.string().required("Phone Number required"),
        passportId: yup.string(),
        zipcode: yup.string(),

        birthDate: yup.string().required("Birth Date required"),
        subCity: yup.string(),
        email: yup
            .string()
            .email("Invalid  email")
            .required(" Email required"),
        gender: yup.string().required("Gender required"),
        touristType: yup.string().required("Tourist Type required"),
        tourCategory: yup
            .array()
            .of(yup.string())
            .nullable(),
        visits: yup
            .array()
            .of(
                yup.object({
                    durationOfStay: yup.string().required("Duration of stay is required")
                })
            )
            .min(1, "At least one visit is required")
            .required("Visits are required"),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: orgValidationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const response = await BACKEND_API.put(
                    `/tourist/edit/${touristId}`,
                    {
                        ...values
                    }
                );
                if (response.status === 200 || response.status === 201) {
                    toast.success(
                        response?.data?.message || "Tourist info updated successfully!"
                    );
                    handleClose();
                }
            } catch (error) {
                toast.error(error?.response?.data?.message || "Network error...");
            } finally {
                setLoading(false);
            }
        },
    });
    useEffect(() => {
        const fetchTourOperatorDetails = async () => {
            try {
                const response = await BACKEND_API.get(`/tourist/getTourists/${touristId}`);
                const userData = response.data;
                setInitialValues({
                    fullName: userData.fullName || "",
                    country: userData.country || "",
                    city: userData.city || "",
                    subCity: userData.subCity || "",
                    gender: userData.gender || "",
                    touristType: userData.touristType || "",
                    tourCategory: userData.tourCategory || [],
                    birthDate: userData.birthDate || "",
                    email: userData.email || "",
                    phoneNum: userData.phoneNum || "",
                    passportId: userData.passportId || "",
                    zipcode: userData.zipcode || "",
                    visits: userData.visits
                        ? userData.visits.map((visit) => ({
                            visitId: visit.visitId || null,  // Ensure visitId is null if not present
                            durationOfStay: visit.durationOfStay || ""
                        }))
                        : [{ durationOfStay: "" }],
                });
            } catch (error) {
                console.error("Error fetching tour operator details:", error);
            }
        };

        if (touristId) {
            fetchTourOperatorDetails();
        }
    }, [touristId]);

    const genderList = ["Male", "Female", "Others"];

    const handleTouristTypeChange = (event, newValue) => {
        formik.setFieldValue("touristType", newValue);
    };

    const handlePackageForDorInterChange = (event, newValue) => {
        formik.setFieldValue("tourCategory", newValue);
    };

    return (          // department: userData.department,

        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update Tourist Detail</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please update Tourist information.
                </DialogContentText>
                <Box component={Paper} p={3} mt={2}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="full name"
                                    {...formik.getFieldProps("fullName")}
                                    error={
                                        formik.touched.fullName &&
                                        Boolean(formik.errors.fullName)
                                    }
                                    helperText={
                                        formik.touched.fullName &&
                                        formik.errors.fullName
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={countrylist}
                                    value={formik.values.country}
                                    onChange={(event, value) =>
                                        formik.setFieldValue("country", value)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            name="country"
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.country &&
                                                Boolean(formik.errors.country)
                                            }
                                            helperText={
                                                formik.touched.country && formik.errors.country
                                            }
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    {...formik.getFieldProps("city")}
                                    error={
                                        formik.touched.city &&
                                        Boolean(formik.errors.city)
                                    }
                                    helperText={
                                        formik.touched.city &&
                                        formik.errors.city
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="sub-City"
                                    {...formik.getFieldProps("subCity")}
                                    error={
                                        formik.touched.subCity &&
                                        Boolean(formik.errors.subCity)
                                    }
                                    helperText={
                                        formik.touched.subCity &&
                                        formik.errors.subCity
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={tourCategoryList || []}
                                    disableCloseOnSelect
                                    value={formik.values.tourCategory}
                                    onChange={handlePackageForDorInterChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Rour Category Category"
                                            error={formik.touched.tourCategory && Boolean(formik.errors.tourCategory)}
                                            helperText={formik.touched.tourCategory && formik.errors.tourCategory}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <Autocomplete
                                    // multiple
                                    id="checkboxes-tags-demo"
                                    options={touristTypeList}
                                    // disableCloseOnSelect
                                    value={formik.values.touristType}
                                    onChange={handleTouristTypeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tourist Type"
                                            error={formik.touched.touristType && Boolean(formik.errors.touristType)}
                                            helperText={formik.touched.touristType && formik.errors.touristType}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={genderList}
                                    value={formik.values.gender}
                                    onChange={(event, value) => formik.setFieldValue("gender", value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Gender"
                                            name="gender"
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.gender && Boolean(formik.errors.gender)}
                                            helperText={formik.touched.gender && formik.errors.gender}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    // label="found At"
                                    {...formik.getFieldProps("birthDate")}
                                    error={
                                        formik.touched.birthDate && Boolean(formik.errors.birthDate)
                                    }
                                    helperText={
                                        formik.touched.birthDate && formik.errors.birthDate
                                    }
                                />
                            </Grid>

                            {formik.values.visits.map((visit, index) => (
                                <Grid item xs={12} md={6} key={index}>
                                    <TextField
                                        fullWidth
                                        label={`Duration of Stay #${index + 1}`}
                                        {...formik.getFieldProps(`visits[${index}].durationOfStay`)}
                                        error={
                                            formik.touched.visits &&
                                            formik.touched.visits[index]?.durationOfStay &&
                                            Boolean(formik.errors.visits?.[index]?.durationOfStay)
                                        }
                                        helperText={
                                            formik.touched.visits &&
                                            formik.touched.visits[index]?.durationOfStay &&
                                            formik.errors.visits?.[index]?.durationOfStay
                                        }
                                    />
                                </Grid>
                            ))}

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label=" Email"
                                    {...formik.getFieldProps("email")}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    {...formik.getFieldProps("phoneNum")}
                                    error={
                                        formik.touched.phoneNum &&
                                        Boolean(formik.errors.phoneNum)
                                    }
                                    helperText={
                                        formik.touched.phoneNum &&
                                        formik.errors.phoneNum
                                    }
                                />
                            </Grid>

                            <Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Passport Id"
                                    {...formik.getFieldProps("passportId")}
                                    error={
                                        formik.touched.passportId &&
                                        Boolean(formik.errors.passportId)
                                    }
                                    helperText={
                                        formik.touched.passportId &&
                                        formik.errors.passportId
                                    }
                                />
                            </Grid><Grid item xs={10} md={8}>
                                <TextField
                                    fullWidth
                                    label="Zipcode"
                                    {...formik.getFieldProps("zipcode")}
                                    error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                                    helperText={formik.touched.zipcode && formik.errors.zipcode}
                                />
                            </Grid>


                        </Grid>
                        <DialogActions>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "red",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#d32f2f",
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#03930a",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#027c08",
                                    },
                                }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress size={20} sx={{ color: "#FFF" }} />
                                ) : (
                                    "Update Tour OP Detail"
                                )}
                            </Button>
                        </DialogActions>
                    </form>
                </Box>
                <ToastContainer position="top-center" />
            </DialogContent>
        </Dialog>
    );
}
