import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import axios from "axios"; // Import axios to handle API calls
import { BACKEND_API } from "../../utils/API";

function ApexChart() {
  const [data, setData] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
    selection: "all",
  });

  const [loading, setLoading] = useState(false); // Handle loading state

  // Function to fetch data from the backend API based on duration
  const fetchData = async (duration) => {
    setLoading(true);
    try {
      const response = await BACKEND_API.get(
        `/tourist/get-tourist-graph-data?duration=${duration}`
      );
      const graphData = response.data;
      setData((prevData) => ({
        ...prevData,
        series: [{ data: graphData }],
      }));

      // Dynamically update the x-axis range based on fetched data
      const minDate = graphData.length ? graphData[0].x : new Date().getTime(); // Assume data has 'x' field for the date
      const maxDate = graphData.length ? graphData[graphData.length - 1].x : new Date().getTime();
      ApexCharts.exec("area-datetime", "zoomX", minDate, maxDate);
    } catch (error) {
      console.error("Error fetching graph data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Update data based on selected duration
  function updateData(timeline) {
    setData((prevData) => ({ ...prevData, selection: timeline }));
    fetchData(timeline); // Fetch data whenever the duration is updated
  }

  useEffect(() => {
    fetchData(data.selection); // Fetch initial data on component mount
  }, []);

  return (
    <div id="chart">
      <div className="toolbar">
        <button
          id="one_month"
          onClick={() => updateData("1month")}
          className={data.selection === "1month" ? "active" : ""}
        >
          1M
        </button>
        &nbsp;
        <button
          id="three_months"
          onClick={() => updateData("3months")}
          className={data.selection === "3months" ? "active" : ""}
        >
          3M
        </button>
        &nbsp;
        <button
          id="six_months"
          onClick={() => updateData("6months")}
          className={data.selection === "6months" ? "active" : ""}
        >
          6M
        </button>
        &nbsp;
        <button
          id="nine_months"
          onClick={() => updateData("9months")}
          className={data.selection === "9months" ? "active" : ""}
        >
          9M
        </button>
        &nbsp;
        <button
          id="one_year"
          onClick={() => updateData("1year")}
          className={data.selection === "1year" ? "active" : ""}
        >
          1Y
        </button>
        &nbsp;
        <button
          id="all"
          onClick={() => updateData("all")}
          className={data.selection === "all" ? "active" : ""}
        >
          ALL
        </button>
      </div>

      <div id="chart-timeline">
        {loading ? (
          <p>Loading data...</p>
        ) : (
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="area"
            height={350}
          />
        )}
      </div>
    </div>
  );
}

export default ApexChart;
