
import React from "react"
export const columns =  [
    {
        accessorKey: 'officeId',
        header: 'ID',
        enableEditing: false,
        size: 80,
      },    
      {
        accessorKey: 'name',
        header: 'Office Name'
        
      },
      {
        accessorKey: 'address',
        header: 'Office_Address',
      },

      {
        accessorKey: 'latitude',
        header: 'Office_Latitude',
      },    
      {
        accessorKey: 'longitude',
        header: 'Office_Longitude',
      },
      {
        accessorKey: 'description',
        header: 'Office_Description',
      },
      {
        accessorKey: 'destination.name',
        header: 'Office_Destination',
      

      
    }
  ]

 