import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import Constants from "../../../../constants/constants";
import UpdateEmployeeDetail from "./UpdateEmployeeDetail";

function EmployeeDetail(props) {
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false); // Example of disabling logic
  const {
    firstName,
    middleName,
    lastName,
    email,
    phoneNum,
    address,
    gender,
    birthDate,
    createdAt,
    updatedAt,
    destination,
    office = [], // Add departmentName here
  } = location.state?.rowData || {};
  const employeeId = location.state?.rowData?.employeeId;
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false); // Initialize status as false initially

  const [open, setOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleClickOpen = (type) => {
    setOpen(true);
    setPopupType(type);
  };

  const handleClose = () => {
    setOpen(false);
    setPopupType(null);
  };

  useEffect(() => {
    // Fetch the initial status from the API when the component mounts
    const fetchStatus = async () => {
      try {
        const response = await BACKEND_API.get(`/employee/getEmployee/${employeeId}`);
        if (response.status === 200) {
          setStatus(response.data.isActive);
        }
      } catch (error) {
        toast.error("Failed to fetch the initial status");
      }
    };

    fetchStatus();
  }, [employeeId]);

  const officeNames = office?.map((office) => office.name).join(", ");

  const Field = ({ label, value }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>{label}</Typography>
          <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );

  const TextAreaField = ({ label, value }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack direction={"column"} spacing={1}>
          <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>{label}</Typography>
          <TextField
            value={value}
            multiline
            fullWidth
            rows={8}
            variant="outlined"
            InputProps={{
              readOnly: true,
              sx: {
                "& .MuiInputBase-input": {
                  textAlign: "justify",
                  textJustify: "inter-word",
                },
              },
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2,
        height: "74vh",
        position: "relative",
      }}
    >
      <Card
        sx={{
          width: "100%",
          p: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h6"
              component="div"
              sx={{
                textDecoration: "underline",
                backgroundColor: Constants.cyanblue,
                textAlign: "center",
              }}
            >
              Employee Detail
            </Typography>
          }
        />
        <CardContent sx={{ flex: 1, overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Field label="Employee Id" value={employeeId} />
              <Field label="First Name" value={firstName} />
              <Field label="Middle Name" value={middleName} />
              <Field label="Last Name" value={lastName} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Phone Number" value={phoneNum} />
              <Field label="Email" value={email} />
              <Field label="Created at" value={createdAt} />
              <Field label="Updated at" value={updatedAt} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Gender" value={gender} />
              <Field label="Destination" value={destination?.name} />
              <Field label="Address" value={address} />
              <Field label="Birth Date" value={birthDate} />
              <Field label="Offices" value={officeNames} />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                color: "white",
                "&:hover": {
                  backgroundColor: "#027c08",
                },
                width: "20%",
              }}
              onClick={() => handleClickOpen("UpdateRole")}
            >
              Update Detail
            </Button>
          </Box>

          {popupType === "UpdateRole" && (
            <UpdateEmployeeDetail
              employeeId={employeeId}
              open={open}
              handleClose={handleClose}
            />
          )}
          <ToastContainer position="top-center" />
        </CardContent>
      </Card>
    </Box>
  );
}

export default EmployeeDetail;
