import React from "react";
import MDataTable from "../MDataTable";

import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { columns } from "./columns";
import useDeleteData from "../hooks/useDeleteData";
import usePutData from "../hooks/usePutData";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Employees() {
  const [employeeId, setEmployeeId] = React.useState(null);

  let endpoint = `/employee/registerEmployee`;
  let getEndpoint = `/employee/getEmployees`;
  let deleteEndpoint = `/employee/delete/employee`;
  let editEndpoint = `/employee/edit`;


  const {
    data: response,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    isFetching: isFetchingTax,
    error: errorGet,
  } = useGetData(getEndpoint);

  const { mutate, isLoading, isError, data, isSuccess } = usePostData(endpoint);
  const handleNewAdd = async ({ values, table }) => {
    mutate(values);

    table.setCreatingRow(null);
  };

  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: setSuccessUpdate,
    error: errorupdate,
  } = usePutData(editEndpoint,employeeId, data);

  const handleEdit = async ({ row, values, table }) => {
    update({
      endpoint: editEndpoint,
      Id: row.original.employeeId,
      data: values,
    });
    table.setEditingRow(null);
  };

  const { mutateAsync: deleteData, isPending: isDeleting } = useDeleteData(deleteEndpoint, employeeId);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this Employee?")) {
      deleteData({ endpoint: deleteEndpoint, Id: row.original.employeeId });
    }
  };
  const navigate = useNavigate();
  const handleViewClick = (rowData) => {
    navigate('/dashboard/employee-detail', { state: { rowData } })
  }
  return (
    <div>
      <MDataTable
        headerTitle="Employees"
        add="Employee"
        openDeleteConfirmModal={openDeleteConfirmModal}
        handleNewAdd={handleNewAdd}
        handleEdit={handleEdit}
        editable={true}
        columns={columns}
        data={response || []}
        isLoading={isLoadingGet}
        isError={isErrorGet}
        title={"Employee"}
        handleViewClick = {handleViewClick}
      />
            <ToastContainer position="top-center" />

    </div>
  );
}
export default Employees;
