import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { host } from "../../constants";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: "whiteSmoke",
    borderRadius: "15px",
    width: "100%",
    height: "510px",

    maxWidth: "400px",
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
}));

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

function ResetPassword() {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { username, token } = useParams(); // Assuming the URL contains the username and token

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.put(
          `${host}/api/v1/user/resetPassword`,
          {
            token,
            password: values.password,
          }
        );
        toast.success(response.data.message);
        navigate("/");
      } catch (error) {
        toast.error(error.data.message || "An error occurred. Please try again later.");
      }
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.root}>

        <Typography
          align="center"
          component="h1"
          variant="h5"
          style={{ padding: 20 }}
          className={classes.texts}
        >
          <h3>
              <span style={{ color: "#C81810" }}>Visit-</span>Oromia{" "}
            </h3>

          {/* <h1 style={{ color: '#804000', textAlign: 'center', marginTop: '-50px' }}>
            <span style={{ color: '#00897B' }}>Visit-</span>Ethiopia
          </h1> */}



        </Typography>
        <Typography variant="h7">To Reset Password Provide new Password</Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            className={classes.textField}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            className={classes.textField}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ backgroundColor: "rgb(238 102 45)" }}

            className={classes.submit}
          >
            Reset Password
          </Button>
        </form>
        {message && (
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              {message}
            </Typography>
          </Box>
        )}
      </div>
      <ToastContainer position="top-center" />

    </div>
  );
}

export default ResetPassword;
