import React, { useState } from "react";
import { host } from "../../constants";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    backgroundColor: "whiteSmoke",
    borderRadius: "15px",
    width: "100%",
    height: "510px",

    maxWidth: "400px",
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
}));

const validationSchema = Yup.object({
  username: Yup.string()
    .required("Email is required")  // Check that email is provided
    .email("Invalid email address") // Validate email format
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address format"), // Custom regex for additional format checks

});

function ForgotPassword() {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${host}/api/v1/user/forgotPassword/${values.username}`
        );
        toast.success(response.data.message);
      } catch (error) {
        toast.error("An error occurred. Please try again later.");
      }
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Typography
          align="center"
          component="h1"
          variant="h5"
          style={{ padding: 20 }}
          className={classes.texts}
        >
          <h3>
            <span style={{ color: "#C81810" }}>Visit-</span>Oromia
          </h3>

          {/* <h3 style={{ color: '#804000', textAlign: 'center', marginTop: '-50px' }}>
            <span style={{ color: '#00897B' }}>Visit-</span>Ethiopia
          </h3> */}

        </Typography>
        <Typography variant="h7">Please provide your email!</Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            onChange={formik.handleChange}
            value={formik.values.username}
            className={classes.textField}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ backgroundColor: "rgb(238 102 45)" }}
            className={classes.submit}
          >
            Submit Email
          </Button>
        </form>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default ForgotPassword;
