import React, { useEffect, useState } from "react";
import { AppBar, Stack, Box, Toolbar, Button, useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "./../../assets/images/visit_oromia_logo-removebg-preview.png";
import Profile from "./Profile"; // Import the Profile component

const Index = ({ toggleDrawer }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, [location]);

  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('token');
});

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <AppBar
      sx={{
        backgroundColor: "#FFF",
        height: "auto",
        position: "sticky",
        justifyContent: "center",
        alignItems: "space-between",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          paddingX: "42px",
          marginLeft: 0,
        }}
      >
        <Stack
          spacing={"60px"}
          direction={"row"}
          alignItems={"center"}
          sx={{ flexGrow: 1, display: { xs: "flex" } }}
        >
          <img
            style={{
              width: "107px",
              height: "74px",
              cursor: "pointer",
            }}
            src={logo}
            alt="logo"
          />
        </Stack>
        <Box>
          {/* Conditional rendering based on the presence of the token */}
          {token ? (
            <Profile token={token} />
          ) : (
            <Button
              style={{
                color: '#804000',
                borderRadius: '16px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Add a shadow to indicate it's active
                padding: '6px 24px', // Increase padding for a larger button
                fontSize: '16px', // Increase font size for a larger text
                textTransform: 'none' // Ensure the text stays as "Login"

              }}
              sx={{ marginLeft: 2 }}
              onClick={handleLoginRedirect}
            >
              Log in
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Index;
