import React from "react";
import MDataTable from "../MDataTable";

import usePostData from "../hooks/usePostData";
import useGetData from "../hooks/useGetData";
import { columns } from "./columns";
import useDeleteData from "../hooks/useDeleteData";
import usePutData from "../hooks/usePutData";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Tourists() {
  const [touristId, setTouristId] = React.useState(null);

  let endpoint = `/tourist/registerTourist`;
  let getEndpoint = `/tourist/getTourist`;
  let deleteEndpoint = `/tourist/delete/tourist`;
  let editEndpoint = `/tourist/edit`;


  const {
    data: response,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    isFetching: isFetchingTax,
    error: errorGet,
  } = useGetData(getEndpoint);
  console.log("tourtest:", response)
  const { mutate, isLoading, isError, data, isSuccess } = usePostData(endpoint);
  const handleNewAdd = async ({ values, table }) => {
    mutate(values);

    table.setCreatingRow(null);
  };

  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: setSuccessUpdate,
    error: errorupdate,
  } = usePutData(editEndpoint, touristId, data);

  const handleEdit = async ({ row, values, table }) => {
    update({
      endpoint: editEndpoint,
      Id: row.original.touristId,
      data: values,
    });
    table.setEditingRow(null);
  };

  const { mutateAsync: deleteData, isPending: isDeleting } = useDeleteData(deleteEndpoint, touristId);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this Tourist?")) {
      deleteData({ endpoint: deleteEndpoint, Id: row.original.touristId });
    }
  };

  const navigate = useNavigate();
  const handleViewClick = (rowData) => {
    navigate('/dashboard/tour-detail', { state: { rowData } })
  }

  return (
    <div>
      <MDataTable
        headerTitle="Tourists"
        add="Tourist"
        openDeleteConfirmModal={openDeleteConfirmModal}
        handleNewAdd={handleNewAdd}
        handleEdit={handleEdit}
        editable={true}
        columns={columns}
        data={response || []}
        isLoading={isLoadingGet}
        isError={isErrorGet}
        title={"Tourist"}
        handleViewClick={handleViewClick}
      />
      <ToastContainer position="top-center" />

    </div>
  );
}
export default Tourists;
