import React, { useState, useEffect } from 'react';
import { Box, TextField, Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BACKEND_API } from '../../../../utils/API';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectMultiTourOperator = ({ setSelectedTourOperator, selectedTourOperator, handleTourOperatorChange }) => {
  const [tourOperators, setTourOperators] = useState([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await BACKEND_API.get(`/tourOperator/getTourOperators`);
        const tourOperatorData = response.data;
        setTourOperators(tourOperatorData);
      } catch (error) {
        console.error('Error fetching tour operator:', error);
      }
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (selectedTourOperator) {
      setSelectedTourOperator(selectedTourOperator);
    }
  }, [selectedTourOperator]);

  return (
    <Autocomplete
    multiple
    id="checkboxes-tags-demo"
    options={tourOperators}
    disableCloseOnSelect
    value={selectedTourOperator}
    onChange={(event, newValue) => {
        handleTourOperatorChange(event, newValue); // Call the handleOrganizationChange function
      }}
      isOptionEqualToValue={(option, value) => option.tourOperatorId === value.tourOperatorId}
      getOptionLabel={(option) => option.tourOrgName}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.tourOrgName}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tour Operator" />
      )}
    />
  );
};

export default SelectMultiTourOperator;
