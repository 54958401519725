import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import Constants from "../../../../constants/constants";
import UpdateTourist from "./UpdateTourist";

function TouristDetail(props) {
  const location = useLocation();


  const [open, setOpen] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleClickOpen = (type) => {
    setOpen(true);
    setPopupType(type);
  };

  const handleClose = () => {
    setOpen(false);
    setPopupType(null);
  };

  const {
    touristId,
    fullName,
    country,
    city,
    subCity,
    gender,
    firstVisitedDate,
    touristType,
    tourCategory,
    updatedAt,
    birthDate,
    email,
    phoneNum,
    passportId,
    zipcode,
    visits = [],
  } = location.state?.rowData || {};
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await BACKEND_API.get(`/tourist/getTourists/${touristId}`);
        if (response.status === 200) {
          setStatus(response.data.isActive);
        }
      } catch (error) {
        toast.error("Failed to fetch the initial status");
      }
    };

    fetchStatus();
  }, [touristId]);

  const VisitisInfo = visits?.map(visit => visit.visitId).join(', ');
  const visitsDuratin = visits?.map(visit => visit.durationOfStay).join(', ');
  const visitDestination = visits?.map(visit => visit?.destination?.name);
  const VisitedAt = visits?.map(visit => visit.visitedAt).join(', ');



  const Field = ({ label, value }) => {
    return (
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{label}</Typography>
        <Typography sx={{ fontSize: "16px", mt: 0.5 }}>{value}</Typography>
      </Stack>
    );
  };

  const TextAreaField = ({ label, value }) => {
    return (
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>{label}</Typography>
        <TextField
          value={value}
          multiline
          fullWidth
          rows={8}
          variant="outlined"
          InputProps={{
            readOnly: true,
            sx: {
              "& .MuiInputBase-input": {
                textAlign: "justify",
                textJustify: "inter-word",
              },
            },
          }}
          sx={{ mt: 0.5 }}
        />
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2,
        height: "74vh",
        position: "relative",
      }}
    >
      <Card
        sx={{
          width: "100%",
          p: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h6"
              component="div"
              sx={{
                textDecoration: "underline",
                backgroundColor: Constants.cyanblue,
                textAlign: "center",
              }}
            >
              Tourist Detail
            </Typography>
          }
        />
        <CardContent sx={{ flex: 1, overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Field label="Tourist Full Name:" value={fullName} />
              <Field label="Tourist Id:" value={touristId} />
              <Field label="Address:" value={`Country: ${country}, City:${city}, Sub-city: ${subCity}`} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Created at:" value={firstVisitedDate} />
              <Field label="Updated at:" value={updatedAt} />
              <Field label="Gender:" value={gender} />

            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Tourist Type:" value={touristType} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field label="Tour Category:" value={tourCategory?.join(', ')} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Birth Date:" value={birthDate} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="First Visited Date:" value={firstVisitedDate} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field label="Concat and Specific Info:"  value={`Phone Number: ${phoneNum}, Email: ${email}, PassPortId: ${passportId}, Zipcode: ${zipcode}`} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field label="Visit Info:"  value={`Visit ID: ${VisitisInfo}, Visit Duration of stay: ${visitsDuratin}, Visited Destination: ${visitDestination}, Visited At: ${VisitedAt}`} />
            </Grid>
            
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green", // Set background color to #03930a
                color: "white", // Set text color to white or any color you prefer
                "&:hover": {
                  backgroundColor: "#027c08", // Change color on hover if needed
                },
                width: "20%", // Reduce the button size
              }}
              onClick={() => handleClickOpen("UpdateRole")}
            >
              Update Detail
            </Button>
          </Box>

          {popupType === "UpdateRole" && (
            <UpdateTourist
              touristId={touristId}
              open={open}
              handleClose={handleClose}
            />
          )}

          <ToastContainer position="top-center" />
        </CardContent>
      </Card>
    </Box>
  );
}

export default TouristDetail;
