const Colors = {
    mode: "light",
    info: {
      main: "#03930A",
      light: "#4CB051",
      dark: "#2C8A90",
    },
    warning: {
      main: "#F58022",
      light: "#FE9D50",
      dark: "#FD760A",
    },
    text: {
      main: "#FFF",
      light: "#D9D9D9",
    },
    grey: {
      100: "#f8f9fa",
      200: "#e9ecef",
      300: "#D9D9D9",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#878686",
      700: "#495057",
      800: "#343a40",
      900: "#212529",
    },
    light: {
      main: "#fff",
    },
    lightGrey: {
      main: "#dee2e6",
    },
    darkGrey: {
      main: "#878686",
    },
    black: {
      main: "#000",
    },
    secondary: {
      main: "#FFF",
    },
  };
  export default Colors;
  