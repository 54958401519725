import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box, Stack } from "@mui/material";
import RSTypography from "./RSTypography";
import logo from "./../assets/images/visit_oromia_logo-removebg-preview.png";
import wonchi from "./../assets/drone-0218-scaled-e1705062363196.jpg";
import bahirdar from "./../assets/Axum_Ethiopia_Welcome-Africa-Tours.jpg";
import chaebara from "./../assets/Chebera-Paw-Lodge.png";


export default function Homepage() {
  // Static data for carousel
  const popularPlaces = [
    {
      image: wonchi, // Replace with the actual image path
      title: "Beautiful Landscape",
      description: "A breathtaking view of nature at its finest.This picturesque destination offers a unique experience for nature lovers and adventure seekers alike.",
    },
    {
      image: bahirdar, // Replace with the actual image path
      title: "Historic Landmark in Ethiopia",
      description: "An ancient structure with a rich history.The ruins of the ancient Aksumite Civilization covered a wide area in the Tigray Plateau. ressive monuments are the monolithic obelisks.",
    },
    {
      image: chaebara, // Replace with the actual image path
      title: "Modern Cityscape",
      description: "A vibrant and dynamic National Park with stunning architecture. Chebera Safari Lodge is an eco-friendly private owned lodge located in the heart of Ethiopia's best-preserved Chebera Churchura National Park.",
    },
  ];

  return (
    <Carousel
      animation="fade"
      swipe={true}
      duration={10}
      stopAutoPlayOnHover={true}
      interval={5000}
      indicatorIconButtonProps={{
        color: "#678",
      }}
      sx={{
        height: "100vh",
        indicators: {
          width: "100%",
          marginTop: "3px",
          textAlign: "center",
        },
        indicator: {
          cursor: "pointer",
          transition: "200ms",
          padding: 0,
          color: "#0A2",
          "&:hover": {
            color: "#678",
          },
          "&:active": {
            color: "#324",
          },
        },
        indicatorIcon: {
          fontSize: "15px",
        },
        active: {
          color: "#2378a1",
        },
      }}
    >
      {popularPlaces.map(({ image, title, description }, i) => (
        <SlideItem
          key={i}
          image={image}
          title={title}
          description={description}
        />
      ))}
    </Carousel>
  );
}

function SlideItem({ image, title, description }) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "88vh",
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        sx={{ height: "100%" }}
      >
        <Box
          sx={{
            backgroundColor: "#000",
            opacity: 0.7,
            width: "100%",
            paddingY: 4,
            paddingX: 2,
          }}
        >
          <center>
            <RSTypography
              fontsize={"35px"}
              fontweight={"500"}
              txtcolor={"#FFF"}
            >
              {title}
            </RSTypography>
            <RSTypography
              fontsize={"20px"}
              fontweight={"300"}
              txtcolor={"#DDD"}
            >
              {description}
            </RSTypography>
          </center>
        </Box>
      </Stack>
    </Box>
  );
}
