import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import Constants from "../../../../constants/constants";
import UpdatePackage from "./EditPackage";

function PackageDetail(props) {
  const location = useLocation();


  const [open, setOpen] = useState(false);
  const [popupType, setPopupType] = useState(null);

  const handleClickOpen = (type) => {
    setOpen(true);
    setPopupType(type);
  };

  const handleClose = () => {
    setOpen(false);
    setPopupType(null);
  };

  const {
    packageId,
    packageName,
    stayDuration,
    packagePricePerPerson,
    maxGroup,
    touristType = [],
    packageForDorInter = [],
    packageDescription,
    departureDates,
    createdAt,
    updatedAt,
    destinations = [],
    tourOperator = [],
  } = location.state?.rowData || {};
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await BACKEND_API.get(`/tourPackage/getpackages/${packageId}`);
        if (response.status === 200) {
          setStatus(response.data.isActive);
        }
      } catch (error) {
        toast.error("Failed to fetch the initial status");
      }
    };

    fetchStatus();
  }, [packageId]);

  const destinationNames = destinations?.map(destination => destination.name).join(', ');
  const tourOperatorNames = tourOperator?.map(tourOperator1 => tourOperator1.tourOrgName).join(', ');

  const Field = ({ label, value }) => {
    return (
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>{label}</Typography>
        <Typography sx={{ fontSize: "12px", mt: 0.5 }}>{value}</Typography>
      </Stack>
    );
  };

  const TextAreaField = ({ label, value }) => {
    return (
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>{label}</Typography>
        <TextField
          value={value}
          multiline
          fullWidth
          rows={8}
          variant="outlined"
          InputProps={{
            readOnly: true,
            sx: {
              "& .MuiInputBase-input": {
                textAlign: "justify",
                textJustify: "inter-word",
              },
            },
          }}
          sx={{ mt: 0.5 }}
        />
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2,
        height: "74vh",
        position: "relative",
      }}
    >
      <Card
        sx={{
          width: "100%",
          p: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={
            <Typography
              variant="h6"
              component="div"
              sx={{
                textDecoration: "underline",
                backgroundColor: Constants.cyanblue,
                textAlign: "center",
              }}
            >
              Package Detail
            </Typography>
          }
        />
        <CardContent sx={{ flex: 1, overflowY: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Field label="Package Name" value={packageName} />
              <Field label="Package Id" value={packageId} />
              <Field label="Package Stay Duration" value={stayDuration} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Package Price Per Person" value={packagePricePerPerson} />
              <Field label="Max Number Of Group" value={maxGroup} />
              <Field label="Created at" value={createdAt} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Updated at" value={updatedAt} />
              <Field label="Departure Date" value={departureDates} />
              <Field label="Tour Categories" value={touristType?.join(', ')} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextAreaField label="Package Description" value={packageDescription} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Tourist Types" value={packageForDorInter?.join(', ')} />
              <Field label="Destinations" value={destinationNames} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field label="Tour Operators" value={tourOperatorNames} />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green", // Set background color to #03930a
                color: "white", // Set text color to white or any color you prefer
                "&:hover": {
                  backgroundColor: "#027c08", // Change color on hover if needed
                },
                width: "20%", // Reduce the button size
              }}
              onClick={() => handleClickOpen("UpdateRole")}
            >
              Update Detail
            </Button>
          </Box>

          {popupType === "UpdateRole" && (
            <UpdatePackage
              packageId={packageId}
              open={open}
              handleClose={handleClose}
            />
          )}

          <ToastContainer position="top-center" />
        </CardContent>
      </Card>
    </Box>
  );
}

export default PackageDetail;
