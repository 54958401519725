import { Typography } from "@mui/material";
import React from "react"
export const columns = [
  {
    accessorKey: 'userId',
    header: 'ID',
    enableEditing: false,
    size: 80,
  },
  {
    accessorKey: 'fullName',
    header: 'Full Name',
  },
  {
    accessorKey: 'username',
    header: 'User Name',
  },
  {
    accessorKey: 'password',
    header: 'Password',
  },
  {
    accessorKey: 'phoneNumber',
    header: 'Phone Number',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'gender',
    header: 'Gender',
    Cell: ({ cell }) => {
      const gender = cell.getValue() || '';
      return <p>{gender}</p>;
    },
    editVariant: 'select',
    editSelectOptions: ['Male', 'Female', 'Others'],
    muiEditTextFieldProps: {
      select: true
    }
  },

  {
    accessorKey: 'isActive',
    header: 'Status',
    Cell: ({ cell }) => (
      cell.getValue() ? (
        <Typography
          sx={{
            color: 'white',
            backgroundColor: 'green',
            border: '1px solid black',
            paddingX: '2px',
          }}
        >
          Active
        </Typography>
      ) : (
        <Typography
          sx={{
            color: 'white',
            backgroundColor: 'red',
            border: '1px solid red',
            paddingX: '2px',
          }}
        >
          Suspended
        </Typography>
      )
    ),
    editVariant: 'select',
    editSelectOptions: ['True', 'False'],
    muiEditTextFieldProps: {
      select: true
    }
  },
  
  
 ];