import React from "react"
export const columns = [
   { accessorKey: "packageId",
   header: "ID", 
   enableEditing: false,
   size: 80,
  },
   {
     accessorKey: "packageName",
     header: "Package Name",
   
   },
   {
     accessorKey: "stayDuration",
     header: "PackageStay duration",
     // width: 150,
     // editable: true,
   },
   {
     accessorKey: "packagePricePerPerson",
     header: "PackagePrice perperson",

   },

   {
     accessorKey: "maxGroup",
     header: "max No.of people",

   },
  //  {
  //    accessorKey: "packageForDorInter",
  //    header: "Tour Categories",

  //  },
  //  {
  //    accessorKey: "touristType",
  //    header: "Tourist Type",

  //  },
  //  {
  //    accessorKey: "packageDescription",
  //    header: "Package Description",

  //  },
  //  {
  //    accessorKey: "departureDates",
  //    header: "Departure Date",

  //  },
  //  {
  //    accessorKey: "createdAt",
  //    header: "PackageCreated Date",

  //  },
  //  {
  //    accessorKey: "destinations",
  //    header: "Destinations",

  //  },
  //  {
  //    accessorKey: "tourOpertaor",
  //    header: "TourOperator supportPackage",

  //  },

//  {
//      accessorKey: 'visits',
//      header: 'Destinations, Duration of Stay',
//      Cell: ({ cell }) => {
//        const visits = cell.getValue() || [];
 
//        if (visits.length > 0) {
//          const visitDetails = visits.map((visit) => (
//            `${visit.destination.name}, Stayed for ${visit.durationOfStay} days`
//          ));
//          return <p>{visitDetails.join(', ')}</p>;
//        } else {
//          return <p>No destination information available</p>;
//        }
//      },
//    },
 ];