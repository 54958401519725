import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Button,
  Grid,
  Avatar,
  CircularProgress,
  Switch,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_API } from "../../../../utils/API";
import Constants from "../../../../constants/constants";
import EditUser from "./EditUser";
import { CAvatar, CImage } from "@coreui/react";
import MaleAvator from "../../../../assets/M_avatar.png";
import FemaleAvator from "../../../../assets/F_avatar2.png";

function UserDetail() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [popupType, setPopupType] = useState("");

  
  const {
    userId,
    username,
    phoneNumber,
    fullName,
    email,
    emailConfirmed,
    gender,
    birthDate,
    description,
    imageUrl,
    createdAt,
    address = {},
    updatedAt,
    twoFactorEnabled,
    tourOperator,
    roles = [],
    destination = {},
  } = location.state?.rowData || {};

  const roleNames = roles?.map(role => role.roleName).join(', ');

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await BACKEND_API.get(`/api/v1/user/getUser/${userId}`);
        if (response.status === 200 || response.status === 201) {
          setStatus(response.data.isActive);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };

    fetchStatus();
  }, [userId]);

  const handleToggleStatus = async () => {
    setLoading(true);
    try {
      const response = await BACKEND_API.put(`/api/v1/user/${userId}/toggle-status`);
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message || `Status Updated successfully!`);
        setStatus((prevStatus) => !prevStatus);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Network error...");
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = (type) => {
    setPopupType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Field = ({ label, value }) => (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {label}:
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {value}
      </Typography>
    </Grid>
  );

  const TextAreaField = ({ label, value }) => (
    <Card>
      <CardContent>
        <Stack direction={"column"} spacing={1}>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            {label}
          </Typography>
          <TextField
            value={value}
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            InputProps={{
              readOnly: true,
              sx: {
                "& .MuiInputBase-input": {
                  textAlign: "justify",
                  textJustify: "inter-word",
                  fontSize: "14px"
                },
              },
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Profile Image Section */}
        <Grid item xs={12} md={12} lg={12} textAlign="center">
          {imageUrl === null ? (
            gender === "Male" ? (
              <CAvatar
                style={{
                  flex: "1",
                  width: "150px",
                  height: "150px",
                  fontSize: "35px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  background: "linear-gradient(#00adef, black)",
                }}
                textColor="white"
                src={MaleAvator}
              />
            ) : (
              <CAvatar
                style={{
                  flex: "1",
                  width: "150px",
                  height: "150px",
                  fontSize: "35px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  background: "linear-gradient(#00adef, black)",
                }}
                textColor="white"
                src={FemaleAvator}
              />
            )
          ) : (
            <CImage
              style={{
                flex: "1",
                width: "150px",
                height: "150px",
                borderRadius: "100px",
              }}
              src={imageUrl}
            />
          )}
        </Grid>

        {/* User Details Section */}
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              title="User Detail"
              sx={{ backgroundColor: Constants.cyanblue, textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Field label="Full Name" value={fullName} />
                <Field label="User ID" value={userId} />
                <Field label="Username" value={username} />
                <Field label="Email" value={email} />
                <Field label="Phone Number" value={phoneNumber} />
                <Field label="Gender" value={gender} />
                <Field label="Birth Date" value={birthDate} />
                <Field label="Created At" value={createdAt} />
                <Field label="Updated At" value={updatedAt} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Additional Details Section */}
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              title="Additional Info"
              sx={{ backgroundColor: Constants.cyanblue, textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Field label="Two Factor Enabled" value={twoFactorEnabled ? "True" : "False"} />
                <Field label="Email Confirmed" value={emailConfirmed ? "True" : "False"} />
                <Field label="Roles" value={roleNames} />
                <Field label="Address" value={`Country: ${address?.country}, City:${address?.city}, Sub-city: ${address?.subCity}, Woreda: ${address?.woreda}, House.No: ${address?.houseNumber}`} />
                <Field label="Destination" value={destination?.name} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Tour Operator Section */}
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              title="Tour Operator Detail"
              sx={{ backgroundColor: Constants.cyanblue, textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Field label="Tour Org Name" value={tourOperator?.tourOrgName} />
                <Field label="Tour Operator Id" value={tourOperator?.tourOperatorId} />
                <Field label="TIN Number" value={tourOperator?.tinNum} />
                <Field label="Owner full-name" value={tourOperator?.ownerFullName} />
                <Field label="Tour-operator office address" value={tourOperator?.ownerAddress} />
                <Field label="Tour-op email" value={tourOperator?.email} />
                <Field label="Office or Individual phone.N" value={tourOperator?.phoneNum} />
                <Field label="Tour-operator found at" value={tourOperator?.foundAt} />
                <Field label="Tour Categories" value={tourOperator?.tourCategory?.join(', ')} />
                <Field label="Tourist Types" value={tourOperator?.touristType?.join(', ')} />
                <Field label="Destinations" value={tourOperator?.destinations?.map(dest => dest.name).join(', ')} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Status and Button Actions */}
      <Stack direction="row" justifyContent="left" sx={{ mt: 4 }}>
        <FormControlLabel
          control={
            <Switch
              checked={status}
              onChange={handleToggleStatus}
              disabled={loading}
            />
          }
          label={`Status: ${status ? "Active" : "Inactive"}`}
        />
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green", // Set background color to #03930a
                color: "white", // Set text color to white or any color you prefer
                "&:hover": {
                  backgroundColor: "#027c08", // Change color on hover if needed
                },
                width: "20%", // Reduce the button size
              }}
              onClick={() => handleClickOpen("UpdateRole")}
            >
              Update Detail
            </Button>
          </Box>

      

      {loading && <CircularProgress size={24} />}

     {popupType === "UpdateRole" && (
      <EditUser open={open} handleClose={handleClose} 
      userId={userId}/>
     ) }
      <ToastContainer />
    </Box>
  );
}

export default UserDetail;
