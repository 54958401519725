import React, { useState, useEffect } from 'react';
import { Box, TextField, Checkbox, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BACKEND_API } from '../../../../utils/API';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DestinationSelect = ({ setSelectedDestination, selectedDestination, handleDestinationChange }) => {
  const [destination, setDestination] = useState([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await BACKEND_API.get(`/destination/getDestinations`);
        const DestinationData = response.data;
        setDestination(DestinationData);
      } catch (error) {
        console.error('Error fetching destination:', error);
      }
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (selectedDestination) {
      setSelectedDestination(selectedDestination);
    }
  }, [selectedDestination]);

  return (
    <Autocomplete
      id="checkboxes-tags-demo"
      options={destination}
      // disableCloseOnSelect
      value={selectedDestination}
      onChange={(event, newValue) => {
        handleDestinationChange(event, newValue); // Call the handleOrganizationChange function
      }}
      isOptionEqualToValue={(option, value) => option.destinationId === value.destinationId}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Destination" />
      )}
    />
  );
};

export default DestinationSelect;
